import Vue from 'vue';

export default {
  getInbox(accountId, noProgress = true) {
    return Vue.axios.get(`inbox/list/${accountId}`, { noProgress: noProgress });
  },

  getRestInbox(accountId, noProgress = true) {
    return Vue.axios.get(`inbox/list/rest/${accountId}`, { noProgress: noProgress });
  },

  getMedia(accountId, mediaId, noProgress = true) {
    return Vue.axios.get(`inbox/chat/media/${accountId}/${mediaId}`, { noProgress: noProgress });
  },

  getChat(chatId, noProgress = true) {
    return Vue.axios.get(`inbox/chat/${chatId}`, { noProgress: noProgress });
  },

  chatRead(chatId, noProgress = true) {
    return Vue.axios.post(`inbox/chat/read/${chatId}`, {}, { noProgress: noProgress });
  },

  performAction(chatId, action, noProgress = true) {
    return Vue.axios.post(`inbox/chat/${action}/${chatId}`, {}, { noProgress: noProgress });
  },

  sendContactTemplate(accountId, contactId, object, noProgress = true) {
    return Vue.axios.post(`inbox/contact/send/template/${accountId}/${contactId}`, object, { noProgress: noProgress });
  },

  sendBroadcastTemplate(accountId, broadcastId, object, noProgress = true) {
    return Vue.axios.post(`inbox/broadcast/send/template/${accountId}/${broadcastId}`, object, { noProgress: noProgress });
  },

  sendChatTemplate(accountId, chatId, object, noProgress = true) {
    return Vue.axios.post(`inbox/chat/send/template/${accountId}/${chatId}`, object, { noProgress: noProgress });
  },

  uploadMedia(accountId, chatId, type, file, noProgress = true) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return Vue.axios.post(`inbox/chat/upload/${type}/${accountId}/${chatId}`, formData, { noProgress: noProgress });
  },

  sendTextMessage(accountId, chatId, object, noProgress = true) {
    return Vue.axios.post(`inbox/chat/send/text/${accountId}/${chatId}`, object, { noProgress: noProgress });
  },

  sendMediaMessage(accountId, type, chatId, object, noProgress = true) {
    return Vue.axios.post(`inbox/chat/send/${type}/${accountId}/${chatId}`, object, { noProgress: noProgress });
  },

  searchMessages(accountId, chatId, keyword, noProgress = true) {
    return Vue.axios.get(`inbox/chat/search/${accountId}/${chatId}?keyword=${keyword}`, { noProgress: noProgress });
  },
}