import moment from 'moment';
import Papa from "papaparse";

import config from '../../../config';

import Misc from '../../../services/misc';
import Campaign from '../../../services/campaign';
import Template from '../../../services/template';

import TemplateContent from '../../../components/template-content/template-content.vue';
import TemplateHeader from '../../../components/template-header/template-header.vue';

export default {
  name: 'AccountCampaigns',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');
    
    this.fetchTemplates();
    this.fetchCampaigns();
  },

  components: {
    TemplateContent,
    TemplateHeader
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    fetchTemplates() {
      this.tloading = true;
      return Template
        .getTemplates(this._id, false)
        .then(x => {
          const templates = x.data || [];
          this.templates = templates.filter(x => x.status == 'approved');
          this.tloading = false;
        })
        .catch(e => {
          console.log(e);
          this.tloading = false;
        });
    },

    fetchCampaigns() {
      this.loading = true;
      return Campaign
        .getCampaigns(this._id, false)
        .then(x => {
          const data = x.data || [];
          this.campaigns = data;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    downloadSampleCSV() {
      window.open(config.sampleCampaignCSV, '_system');
    },

    createItem(item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    editItem(item) {
      this.editedItem = item;
      this.editDialog = true;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    deleteItem(item) {
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    finalDelete() {
      this.processing = true;
      return Campaign.deleteCampaign(this.beingDeleted._id, false)
        .then(() => { 
          this.confirmDialog = false;
          this.processing = false; 
          this.fetchCampaigns(); 
        })
        .catch(e => { 
          this.confirmDialog = false;
          this.processing = false; 
          console.log(e)
        });
    },

    getRef() {
      return this.$refs.inputUpload[0] ? this.$refs.inputUpload[0] : this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileCSV(file) {
      if (file && (file['type'].split('/')[1] === 'csv' || file['type'] === 'application/vnd.ms-excel')) return true;
      return false;
    },

    invalidFileError() {
      this.file = '';
      this.showErr = 'Invalid file format';
      setTimeout(() => { this.showErr = false }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileCSV(file)) return this.invalidFileError();
      this.file = file;

      this.processingCSV = true;
      Papa.parse(file, { 
        complete: (results) => {
          const list = results.data;

          if (list && list.length > 5001) this.throwError('Up to 5000 contacts supported');
          else {
            // process all contacts for preview
            this.contacts = [];
            this.cheaders = [];

            list.forEach((item, index) => {
              if (!index) {
                item.forEach((x, i) => {
                  if (x == 'number') this.cheaders.push({ text: 'Number', value: x });
                  else if (x) {
                    const text = x;
                    let value = x.replaceAll('{{', '');
                    value = '_'+i+(value.replaceAll('}}', ''));
                    this.cheaders.push({ text: text, value: value, sortable: false });
                  }
                });
              }

              else {
                const cobject = {};
                this.cheaders.forEach((i, i2) => {
                  cobject[i.value] = item[i2];
                  cobject[i.text] = item[i2];
                });
                if (cobject.number) {
                  cobject.number = cobject.number.replace(' ', '');
                  cobject.number = cobject.number.trim();
                  
                  const acobj = this.contacts.find(x => x.number == cobject.number);
                  if (!acobj) this.contacts.push(cobject);
                }
              }


            });

            this.cheaders.unshift({ text: '#', value: 'index', sortable: false });
            this.cheaders.push({ text: 'Preview', value: 'preview', align: 'center', sortable: false });

            this.cstep++;
          }
          this.processingCSV = false;
          
        }  
      });
    },

    throwError(message) {
      this.$store.state.snackbar.text = message;
      this.$store.state.snackbar.show = true;
      this.$store.state.snackbar.color = 'error';
    },

    nextStep() {
      if (this.cstep == 1) {
        if (!this.$refs.ctitle.validate()) return;
        this.cstep++;
      }
      else if (this.cstep == 2) {
        const stemplate = this.selected[0];
        this.stemplate = JSON.parse(JSON.stringify(stemplate));

        const components = stemplate.components;
        if (components && components.length) {
          const hc = components.find(x => x.type == 'HEADER');
          if (hc &&  hc.format != 'TEXT') {
            // const format = hc.format;

            // let upload = true;
            // if (format == 'IMAGE' && hc.image) upload = false;
            // else if (format == 'VIDEO' && hc.video) upload = false;
            // else if (format == 'DOCUMENT' && hc.document) upload = false;
            
            // if (upload) this.headerMediaModal = true;
            // else this.cstep++;

            this.headerMediaModal = true;
          }
          else this.cstep++;
        }
      }
      else {
        this.cstep++;
      }
    },

    getTemplateHeaderContent(item) {
      let text = '';
      const components = item.components;
      const header = components.find(x => x.type == 'HEADER');
      if (header && ['text', 'TEXT'].includes(header.format)) text = header.text;
      return text;
    },

    getTemplateBodyContent(item) {
      let text = '';
      const components = item.components;
      const body = components.find(x => x.type == 'BODY');
      text = body.text;
      return text;
    },

    getTemplateFooterContent(item) {
      let text = '';
      const components = item.components;
      const footer = components.find(x => x.type == 'FOOTER');
      if (footer) text = footer.text;
      return text;
    },

    previewTemplate(item) {
      const processed = this.processVariables([item]);
      const vs = processed[0];

      const stemplate = this.stemplate;
      stemplate.hvariables = vs.hvariables;
      stemplate.bvariables = vs.bvariables;

      this.previewItem = JSON.parse(JSON.stringify(stemplate));
      this.previewDialog = true;
    },

    processVariables(contacts) {
      let ch = JSON.parse(JSON.stringify(this.cheaders));
      ch = ch.filter(x => !['index', 'number', 'preview'].includes(x.value));
      const ch1 = ch.filter(x => x.text == '{{1}}');

      let hvheader = [];
      let bvheader = [];
      if (ch1 && ch1.length > 1) {
        hvheader.push(ch1[0]);
        ch.splice(0, 1);
      }
      bvheader = ch;

      const list = [];
      contacts.forEach(x => {
        const item = { 
          number: x.number,
          hvariables: [], 
          bvariables: [] 
        };

        hvheader.forEach(i => {
          const key = i.value;
          item.hvariables.push(x[key]);
        })

        bvheader.forEach(i => {
          const key = i.value;
          item.bvariables.push(x[key]);
        });

        list.push(item);
      });

      return list;
    },

    createCampaign() {
      this.processing = true;
      Misc.snackbarStart();

      const temp = this.stemplate;
      const list = this.processVariables(this.contacts);
      const campaign = {
        title: this.editedItem.title,
        list: list,
        template: {
          _id: temp._id,
          name: temp.name,
          language: temp.language,
          components: temp.components
        }
      };

      return Campaign
        .createCampaign(this._id, campaign)
        .then(() => {
          this.resetCampaign();
          this.dialog = false;
          this.processing = false;
          Misc.snackbarSuccess();
          this.fetchCampaigns();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Misc.snackbarError();
        });
    },

    resetCampaign() {
      this.file = '';
      this.cstep = 1;
      this.tsearch = '';
      this.selected = [];
      this.stemplate = {};
      this.processingCSV = false;
    },

    editCampaign() {
      this.processing = true;
      Misc.snackbarStart();

      const campaign = { title: this.editedItem.title };
      return Campaign
        .editCampaign(this.editedItem._id, campaign, false)
        .then(() => {
          this.editDialog = false;
          this.processing = false;
          Misc.snackbarSuccess();
          this.fetchCampaigns();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Misc.snackbarError();
        });

    }
  },

  watch: {
    headerMediaUploaded(n) {
      if (n) {
        this.headerMediaModal = false;
        this.cstep++;
      }
      this.headerMediaUploaded = false;
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    account() {
      return this.$store.state.activeAccount;
    },

    indexed() {
      return this.campaigns.map((x, index) => {
        x.index = (index + 1)+'.';
        return x;
      })
    },

    cindexed() {
      return this.contacts.map((x, index) => {
        x.index = (index + 1)+'.';
        return x;
      })
    },

    isBasic() {
      return this.account.plan == 'basic';
    }
  },

  data() {
    return {
      _id: '',
      loading: false,
      tloading: false,
      processing: false,
      campaigns: [],

      tsearch: '',
      templates: [],
      selected: [],
      stemplate: {},

      previewItem: {},
      previewDialog: false,
      headerMediaModal: false,
      headerMediaUploaded: false,

      search: '',
      dialog: false,
      editDialog: false,

      editedItem: {},
      defaultItem: {},

      file: '',
      showErr: false,
      insErr: '',
      cstep: 1,
      processingCSV: false,
      processedItems: [],

      contacts: [],
      cheaders: [],

      beingDeleted: {},
      confirmDialog: false,

      headers: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Title', value: 'title' },
        { text: 'Template', value: 'templateName' },
        { text: 'Scheduled', value: 'scheduled', sortable: false, align: 'center' },
        { text: 'Sent', value: 'sent', sortable: false, align: 'center' },
        { text: 'Failed', value: 'failed', sortable: false, align: 'center' },
        { text: 'Created At', value: 'createdAt', sortable: false, align: 'right' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],

      theaders: [
        { text: 'Template', value: 'name' },
        { text: 'Message', value: 'message', width: '50%' },
        { text: 'Created At', value: 'createdAt', align: 'right' }
      ],

      validationRules: {
        basicRule: [
          v => !!v || 'Title is required'
        ],
      }
    }
  }
}