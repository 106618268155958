import Vue from 'vue';
import moment from 'moment';
import Misc from '../../services/misc';

Vue.component('SubHeader', require('../../components/sub-header/sub-header.vue').default);

const accRoutes = [
  'account-dashboard',
  'account-inbox',
  'account-contacts',
  'account-groups',
  'account-agents',
  'account-templates',
  'account-payments',
  'account-profile',
  'account-settings',
  'account-campaigns',
  'account-campaign-info'
]

export default {
  name: 'Home',

  methods: {
    logout() {
      this.$store.commit('logout');
    },

    navigate(name) {
      if (this.aloading) return;
      if (name == 'logout') return this.logout();
      if (name == 'apis') return window.open('https://github.com/waeasyapi/waeasyapi-node/#readme', '_blank');
      this.$router.push({ name: name }).catch(() => {});
    },

    isActive(item) {
      if (item == 'home' && ['accounts', 'home', 'new-account', 'port-account'].includes(this.$route.name)) return true;
      return this.$route.name == item;
    },

    processMenuSetup() {
      if (this.aloading) return;

      if (accRoutes.includes(this.$route.name)) return this.items = this.aitems;
      if (this.amISuperadmin) this.items = this.sitems;
      else this.items = this.ritems; 
      this.items = this.items.concat(this.citems);
    },

    formatDate() {
      return moment().format('lll');
    }
  },

  watch: {
    '$route'(n) {
      if (this.aloading) return;
      if (n && accRoutes.includes(n.name)) this.items = this.aitems;
      else this.processMenuSetup();
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    year() {
      return new Date().getFullYear();
    },

    amISuperadmin() {
      return Misc.amISuperadmin();
    },

    inAccount() {
      return accRoutes.includes(this.$route.name);
    },

    account() {
      return this.$store.state.activeAccount;
    },

    aloading() {
      return this.$store.state.aloading;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    isInbox() {
      return this.$route.name == 'account-inbox';
    }
  },

  created() {
    this.processMenuSetup();
  },

  data() {
    return {
      wlogo: require('@/assets/imgs/wlogo.png'),
      logo: require('@/assets/imgs/logo.png'),

      items: [],
      citems: [
        {
          url: 'profile',
          name: 'Profile',
          icon: 'mdi-account'
        },
        {
          url: 'apis',
          name: 'API Docs',
          icon: 'mdi-code-tags'
        },
        {
          url: 'logout',
          name: 'Sign out',
          icon: 'mdi-logout'
        },
      ],

      sitems: [
        {
          url: 'super-dashboard',
          name: 'Dashboard',
          icon: 'mdi-av-timer'
        },
        {
          url: 'accounts',
          name: 'Accounts',
          icon: 'mdi-whatsapp'
        },
        {
          url: 'payments',
          name: 'Payments',
          icon: 'mdi-currency-usd'
        },
        {
          url: 'countries',
          name: 'Countries',
          icon: 'mdi-earth'
        }
      ],

      ritems: [
        {
          url: 'home',
          name: 'Dashboard',
          icon: 'mdi-av-timer'
        },
        {
          url: 'payments',
          name: 'Payments',
          icon: 'mdi-currency-usd'
        }
      ],

      aitems: [
        {
          url: 'account-dashboard',
          name: 'Home',
          icon: 'mdi-home-outline'
        },
        {
          url: 'account-inbox',
          name: 'Inbox',
          icon: 'mdi-comment-text-multiple'
        },
        {
          url: 'account-contacts',
          name: 'Contacts',
          icon: 'mdi-account-group'
        },
        {
          url: 'account-templates',
          name: 'Templates',
          icon: 'mdi-message-text-outline'
        },
        {
          url: 'account-campaigns',
          name: 'Campaigns',
          icon: 'mdi-chart-pie'
        },
        {
          url: 'account-payments',
          name: 'Payments',
          icon: 'mdi-currency-usd'
        },
        {
          url: 'account-profile',
          name: 'Profile',
          icon: 'mdi-whatsapp'
        },
        {
          url: 'account-settings',
          name: 'Settings',
          icon: 'mdi-cogs'
        },
        {
          url: 'apis',
          name: 'API Docs',
          icon: 'mdi-code-tags'
        },
        {
          url: 'logout',
          name: 'Sign out',
          icon: 'mdi-logout'
        },
      ],

      bitems: [
        {
          url: 'logout',
          name: 'Sign out',
          icon: 'mdi-logout'
        },
      ]
    }
  }
}