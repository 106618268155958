<template>
  <section :class="[
    'text-right', isSticker ? (right ? 'mt-1' : 'mt-9') : '',
    right && isAudio && !msg.file ? 'mr-3' : '',
    right && isAudio ? 'pb-1 pr-1' : '',
    right && isImage ? 'pr-2' : '',
    right && isVideo ? 'mr-2' : '',
    isVideo && (msg.file || msg.video.link) && !msg.video.caption ? 'cvitmer' : '',
    isImage && (msg.file || msg.image.link) && !msg.image.caption ? 'mitimer' : '']">
    <div v-if="['text', 'template'].includes(msg.type)" :class="['timer mt-1 mb-1', right ? 'd-inline' : '']">
      <span style="font-size: 0.8rem">{{ formatTime(msg.createdAt) }}</span>
    </div>

    <div v-else style="display: inline-grid;">
      <div v-if="msg.type == 'video'" class="vtimer" :style="right ? 'margin-right: 17px' : ''">
        <span style="font-size: 0.8rem">{{ formatTime(msg.createdAt) }}</span>
      </div>

      <div v-else :class="['timer mb-1', tclass]">
        <span style="font-size: 0.8rem">{{ formatTime(msg.createdAt) }}</span>
      </div>
    </div>
    
    <MessageTicker v-if="right" :right="right" :msg="msg" class="ml-1"></MessageTicker>
  </section>
</template>
<script> 
import moment from 'moment';
import MessageTicker from '../message-ticker/message-ticker.vue';

export default {
  name: 'MessageTimer',

  props: [
    'msg',
    'right'
  ],

  components: {
    MessageTicker
  },

  methods: {
    formatTime(date) {
      return moment(date).format('D MMM, h:mm a');
    },
  },

  computed: {
    isSticker() {
      return this.msg && this.msg.type == 'sticker';
    },

    isImage() {
      return this.msg && this.msg.type == 'image';
    },

    isVideo() {
      return this.msg && this.msg.type == 'video';
    },

    isAudio() {
      return ['audio', 'voice'].includes(this.msg.type);
    },

    tclass() {
      if (!this.msg) return;

      let cname = 'mtimer mr-2';
      if (['voice', 'audio'].includes(this.msg.type)) cname = 'mtimer-audio mr-2';
      if (['image'].includes(this.msg.type) && !this.msg.file) {
        cname = 'mtimer-image';
        if (!this.right) cname += ' mr-2';
      }
      if (['sticker'].includes(this.msg.type) && !this.msg.file) cname = 'mtimer-sticker mr-2';
      return cname
    }
  },

  data() {
    return {

    }
  }
}
</script>