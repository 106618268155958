import Vue from 'vue'
import VueRouter from 'vue-router';
import NProgress from 'nprogress';

Vue.use(VueRouter);

import Home from '../views/home/home.vue';
import Signup from '../views/signup/signup.vue';
import Dashboard from '../views/dashboard/dashboard.vue';
import Countries from '../views/countries/countries.vue';

import Profile from '../views/profile/profile.vue';
import Payments from '../views/payments/payments.vue';
import Accounts from '../views/accounts/accounts.vue';
import NewAccount from '../views/new-account/new-account.vue';

import AccountInit from '../views/account/init/init.vue';
import AccountInbox from '../views/account/inbox/inbox.vue';
import AccountAgents from '../views/account/agents/agents.vue';
import AccountProfile from '../views/account/profile/profile.vue';
import AccountContacts from '../views/account/contacts/contacts.vue';
import AccountPayments from '../views/account/payments/payments.vue';
import AccountSettings from '../views/account/settings/settings.vue';
import AccountCampaigns from '../views/account/campaigns/campaigns.vue';
import AccountTemplates from '../views/account/templates/templates.vue';
import AccountDashboard from '../views/account/dashboard/dashboard.vue';

import AccountCampaignInfo from '../views/account/campaign/campaign.vue';

import store from '../store';

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: Signup,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'super-dashboard',
        component: Dashboard
      },
      {
        path: '',
        name: 'home',
        component: Accounts
      },
      {
        path: 'default',
        redirect: '/home',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/countries',
        name: 'countries',
        component: Countries,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounts',
        name: 'accounts',
        component: Accounts,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/payments',
        name: 'payments',
        component: Payments,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/account/new/',
        name: 'new-account',
        component: NewAccount,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/account/draft/:_id',
        name: 'draft-account',
        component: NewAccount,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/account/port/',
        name: 'port-account',
        component: NewAccount,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/account/:_id([a-f\\d]{24})',
        name: 'account-init',
        component: AccountInit,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'account-dashboard',
            component: AccountDashboard,
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'inbox',
            name: 'account-inbox',
            component: AccountInbox,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'contacts',
            name: 'account-contacts',
            component: AccountContacts,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'agents',
            name: 'account-agents',
            component: AccountAgents,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'templates',
            name: 'account-templates',
            component: AccountTemplates,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'campaigns',
            name: 'account-campaigns',
            component: AccountCampaigns,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'campaigns/:campaignId',
            name: 'account-campaign-info',
            component: AccountCampaignInfo,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'payments',
            name: 'account-payments',
            component: AccountPayments,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'profile',
            name: 'account-profile',
            component: AccountProfile,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'settings',
            name: 'account-settings',
            component: AccountSettings,
            meta: {
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  scrollBehavior(to, from) {
    if (from.name == 'user-detail') return;
    else return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
  mode: 'history'
});

//auth guard
router.beforeEach((to, from, next) => {
  //for real-time if user deletes token from storage
  if (!localStorage.getItem('token')) store.commit('authError'); 
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      router.push('/signup').catch(() => {});
      next(false);
    } else {
      if (!store.state.userInfo._id) {
        const token = store.state.token;
        Vue.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        store.dispatch('verify')
          .then(() => next())
          .catch(() => router.push('/signin').catch(() => {}));
      } else {
        next();
      }
    }
  } else {
    //if user forceRefresh the page
    if (store.getters.isAuthenticated && !store.state.userInfo._id) {
      const token = store.state.token;
      Vue.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
      store.dispatch('verify')
        .then(() => {
          if (to.name === 'signin' || to.name === 'signup') router.push('/').catch(() => {});
          else next()
        })
        .catch(() => router.push('/signin').catch(() => {}));
    } else {
      if (store.getters.isAuthenticated && (to.name === 'signin' || to.name === 'signup')) return next(false);
      else next();
    }
  
  }
})

/*as we've added loading for http now*/
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
});

export default router;