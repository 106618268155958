import Misc from '../../services/misc';
import Contact from '../../services/contact';

export default {
  name: 'LabelEditor',

  props: [
    'editedItem',
    'labelsModified'
  ],

  methods: {
    navigate(name, params = {}) {
      this.$store.state.labelEditorModal = false;
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    processLabels() {
      this.labels.forEach(x => {
        x.isChecked = false
        if (this.editedItem.labels) {
          if (this.editedItem.labels.some(y => y == x._id || y._id == x._id)) x.isChecked = true;
        }
      });
    },

    fetchLabels() {
      this.loading = true;
      return Contact
        .getLabels(this.account._id, false)
        .then(x => {
          this.labels = x.data;
          this.processLabels();

          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        })
    },

    closeDialog() {
      this.$store.state.labelEditorModal = false;
      this.labels.forEach(x => x.isChecked = false);
    },

    saveLabels() {
      const selectedLabels = this.labels.filter(x => x.isChecked);
      const lebels = selectedLabels.map(x => x._id);

      const body = { labels: lebels };

      Misc.snackbarStart();
      this.processing = true;

      let Request;
      if (this.editedItem.isBroadcast) Request = Contact.editBroadcast(this.editedItem._id, body, false);
      else Request = Contact.editContact(this.editedItem._id, body, false);

      return Request
        .then(() => {
          this.$emit('update:labelsModified', selectedLabels);
          this.processing = false;
          this.closeDialog();
          Misc.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
        });
    }
  },

  computed: {
    account() {
      return this.$store.state.activeAccount;
    }
  },

  watch: {
    '$store.state.labelEditorModal'(n) {
      if (n) {
        if (!this.labels || !this.labels.length) this.fetchLabels();
        else this.processLabels();
      }
    }
  },

  data() {
    return {
      loading: false,
      processing: false,

      labels: []
    }
  }
}