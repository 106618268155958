import moment from 'moment';
import _ from 'underscore';

import { randomColor } from 'randomcolor'; 
import config from '../../../config';

import Contact from '../../../services/contact';
import Misc from '../../../services/misc';

import ContactForm from '../../../components/contact-form/contact-form.vue';


export default {
  name: 'AccountContacts',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');

    this.fetchContacts();
    this.fetchLabels();
    this.fetchBroadcasts();

    //if google access token served
    setTimeout(() => this.checkGoogleRedirection(), 100);
  },

  components: {
    ContactForm
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    getURL(data) {
      return Misc.getImageURL(data);
    },

    initPusher(eventName = 'googleSyncCompleted') {
      const channelName = this._id;

      const pusher = this.$store.state.pusher;
      pusher.unsubscribe(channelName);
      const channel = pusher.subscribe(channelName);
      channel.unbind(eventName);
      channel.bind(eventName, (data) => { 
        if (this.$route.name == 'account-contacts') {
          this.loading = true;
          this.$store.state.gcsRequest = false;
          this.$store.state.gcfyRequest = false;
          this.fetchContacts(1); 
        }
        this.notify(data.message); 
      });
    },

    notify(title, body = {}) {
      this.$notification.show(title, body, {});
    },

    fetchContacts(pageNumber = this.contactsObject.pageNumber, itemsPerPage = this.contactsObject.itemsPerPage, keyword = '') {
      this.loading = true;

      const sortBy = {};
      if (this.options.sortBy && this.options.sortBy.length) sortBy[this.options.sortBy[0]] = this.options.sortDesc[0] ? -1 : 1;
      else sortBy.createdAt = -1;

      this.filters.sortBy = sortBy;
      return Contact
        .getContacts(this._id, pageNumber, itemsPerPage, this.filters, keyword, false)
        .then(x => {
          const data = x.data;
          this.contactsObject = data;
          this.contacts = data.items;
          this.loading = false;
          this.firstLoad = true;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchBroadcasts(pageNumber = this.broadcastsObject.pageNumber, itemsPerPage = this.broadcastsObject.itemsPerPage, keyword = '') {
      this.bloading = true;
      return Contact
        .getBroadcasts(this._id, pageNumber, itemsPerPage, this.bfilters, keyword, false)
        .then(x => {
          const data = x.data;
          this.broadcastsObject = data;
          this.broadcasts = data.items;
          this.bloading = false;
          this.firstLoad = true;
        })
        .catch(e => {
          console.log(e);
          this.bloading = false;
        });
    },

    fetchLabels() {
      this.lloading = true;
      return Contact
        .getLabels(this._id, false)
        .then(x => {
          const data = x.data;
          this.labels = data;
          this.lloading = false;
        })
        .catch(e => {
          console.log(e);
          this.lloading = false;
        });
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    editItem(item) {
      this.editedIndex = this.contacts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      setImmediate(() => this.$store.state.contactFormModal = true, 100);
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      if (this.beingDeleted.isLabel) return this.finalLabelDelete();
      if (this.beingDeleted.isBroadcast) return this.finalBroadcastDelete();

      this.processing = true;
      return Contact.deleteContact(this.beingDeleted._id, false)
        .then(() => { 
          const cobj = this.contactsObject;
          this.confirmDialog = this.processing = false; 
          this.fetchContacts(cobj.pageNumber, cobj.itemsPerPage, this.search); 
        })
        .catch(e => { this.confirmDialog = this.processing = false; console.log(e)});
    },

    close() {
      this.dialog = this.processing = this.loading = false
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    save() {
      if (!this.$refs.form.validate()) return;
      Misc.snackbarStart();


      if (this.selectedCountry._id) {
        this.editedItem.callingCode = this.selectedCountry.callingCode;
      }

      let Request;
      this.processing = true;
      const contact = this.editedItem;
      if (contact._id) Request = Contact.editContact(contact._id, contact, false);
      else Request = Contact.addContact(this._id, contact, false);

      return Request
        .then(() => {
          this.dialog = false;
          this.processing = false;
          Misc.snackbarSuccess();
          
          const cobj = this.contactsObject;
          this.fetchContacts(cobj.pageNumber, cobj.itemsPerPage, this.search); 
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
          this.$store.state.snackbar.text = 'Most likely contact already exists';
        })
    },

    resetColor(onlyColor = false) {
      if (!onlyColor) this.editedLabelItem = { color: this.anyColor() };
      else this.editedLabelItem.color = this.anyColor();
    },

    editLabelItem(item) {
      this.editedLabelItem = Object.assign({}, item)
      setImmediate(() => this.labelDialog = true, 100);
    },

    deleteLabelItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
      this.beingDeleted.isLabel = true;
    },

    finalLabelDelete() {
      Misc.snackbarStart();
      this.processing = true;
      return Contact.deleteLabel(this.beingDeleted._id, false)
        .then(() => { 
          this.processing = false; 
          this.confirmDialog = false;
          this.fetchLabels(); 
          Misc.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.confirmDialog = false;
          this.processing = false; 
          Misc.snackbarError();
        });
    },

    saveLabel() {
      if (!this.$refs.form2.validate()) return;
      Misc.snackbarStart();
      let Request;
      this.processing = true;

      const label = this.editedLabelItem;
      if (label._id) Request = Contact.editLabel(label._id, label, false);
      else Request = Contact.createLabel(this._id, label, false);

      return Request
        .then(() => {
          this.processing = false;
          Misc.snackbarSuccess();
          this.fetchLabels();
          this.resetColor();
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
        })
    },

    text(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    contactText(item) {
      return !item ? '' : `${item.name} (+${item.callingCode} ${item.mobile})`;
    },

    checkGoogleRedirection() {
      const matches = window.location.hash.match(/access_token=([^&]*)/);
      if (matches) {
        const accessToken = matches[1];
        this.submitGoogleContactsRequest(accessToken);
      }
    },

    initGoogleContactsSync() {
      const login = this.getAuthUri();
      window.location.replace(login);
    },

    getAuthUri() {
      let base = window.location.href, state = "";
      let i = base.indexOf("#");
      if (i > -1) {
        state = base.substring(i);
        base = base.substring(0, i);
      }

      return (
        "https://accounts.google.com/o/oauth2/v2/auth" +
        "?client_id=" +
        encodeURIComponent(config.google.clinetID) +
        "&redirect_uri=" +
        encodeURIComponent(base) +
        "&state=" +
        encodeURIComponent(btoa(state)) +
        "&response_type=" +
        encodeURIComponent("token") +
        "&scope=" +
        encodeURIComponent("profile https://www.googleapis.com/auth/contacts.readonly") +
        "&include_granted_scopes=" +
        encodeURIComponent("true")
      );
    },

    requestSubmitted() {
      const message = 'Your Google Contacts Sync request has been initiated and it\'s under process. Once completed, you\'ll be notified and all of your Google Contacts will be imported here. Please do not initiate a new request immediately. Thanks.'
      this.$store.state.snackbar.text = message;
      this.$store.state.snackbar.timeout = 15000;
      this.$store.state.snackbar.show = true;
    },

    submitGoogleContactsRequest(token) {
      if (!token || this.$store.state.gcsRequest) return;
      this.gprocessing = true;
      this.$store.state.gcsRequest = true;
      return Contact.syncGoogleContacts(this._id, { token: token }, false)
        .then(r => {
          this.google = r.data;
          this.gprocessing = false;
          this.requestSubmitted();
          this.initPusher();
        })
        .catch(e => {
          console.log(e);
          this.gprocessing = false;
        });
    },

    anyColor() {
      const colors = config.colors;
      return colors[Math.floor(Math.random()*colors.length)];
    },

    editBroadcastItem(item) {
      this.editedBroadcastIndex = this.broadcasts.indexOf(item)
      this.editedBroadcastItem = Object.assign({}, item)
      setImmediate(() => this.broadcastDialog = true, 100);
    },

    closeBroadcast() {
      this.broadcastDialog = this.processing = this.loading = false;
      this.editedBroadcastItem = Object.assign({}, this.defaultBroadcastItem);
    },

    deleteBroadcastItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
      this.beingDeleted.isBroadcast = true;
    },

    finalBroadcastDelete() {
      Misc.snackbarStart();
      this.processing = true;
      return Contact.deleteBroadcast(this.beingDeleted._id, false)
        .then(() => { 
          const cobj = this.broadcastsObject;
          this.confirmDialog = this.processing = false; 
          this.fetchBroadcasts(cobj.pageNumber, cobj.itemsPerPage, this.bsearch); 
          Misc.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.confirmDialog = false;
          this.processing = false; 
          Misc.snackbarError();
        });
    },

    saveBroadcast() {
      if (!this.$refs.form3.validate()) return;
      Misc.snackbarStart();

      let Request;
      this.processing = true;

      const broadcast = Object.assign({}, this.editedBroadcastItem);
      broadcast.recipients = broadcast.recipients.map(x => x._id || x);

      if (broadcast._id) Request = Contact.editBroadcast(broadcast._id, broadcast, false);
      else Request = Contact.createBroadcast(this._id, broadcast, false);

      return Request
        .then(() => {
          this.broadcastDialog = false;
          this.processing = false;
          this.fetchBroadcasts(1);
          Misc.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
        })
    },

    querySelections: _.debounce(function() {
      this.lastSearched = this.psearch;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      return Contact
        .getContacts(this._id, 1, 10, {}, this.psearch, false)
        .then(x => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.recipients = x.data ? x.data.items : [];
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    }, 1000),

    recipientSelected() {
      if (!this.selectedItem) return;
      this.lastSearched = this.selectedItem.name;
      if (this.editedBroadcastItem.recipients.find(x => x._id === this.selectedItem._id)) return;

      this.selectedItem.index = this.editedBroadcastItem.recipients.length + 1;
      this.selectedItem.color = this.anyColor();
      this.editedBroadcastItem.recipients.push(this.selectedItem);
      this.selectedItem = {};
      this.psearch = '';
    },

    removeItem(item) {
      const filteredItems = this.editedBroadcastItem.recipients.filter(x => x._id != item._id);
      this.editedBroadcastItem.recipients = filteredItems;
    },

    chatWith(type, item) {
      const info = { type: type, info: item };
      this.$store.state.rcInfo = info;
      this.navigate('account-inbox', { _id: this._id });
    },

    initGrowcifySync() {
      this.gcfyDialog = true;
    },

    requestSubmitted2() {
      const message = 'Your Growcify Customers Sync request has been initiated and it\'s under process. Once completed, you\'ll be notified and all of your Growcify Customers will be imported here. Please do not initiate a new request immediately. Thanks.'
      this.$store.state.snackbar.text = message;
      this.$store.state.snackbar.timeout = 15000;
      this.$store.state.snackbar.show = true;
    },

    syncGrowcifyCustomers() {
      //if (this.$store.state.gcfyRequest) return;

      this.gcfyprocessing = true;
      this.$store.state.gcfyRequest = true;
      return Contact
        .syncGrowcifyCustomers(this._id, false)
        .then(() => {
          this.gcfyDialog = false;
          this.gcfyprocessing = false;
          this.requestSubmitted2();
          this.initPusher('growcifySyncCompleted');
        })
        .catch(e => {
          console.log(e);
          this.gprocessing = false;
        });
    } 
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    indexed() {
      return this.contacts.map((x, index) => {
        x.index = (index + 1)+'.';
        x.color = this.anyColor();
        return x;
      })
    },

    bindexed() {
      return this.broadcasts.map((x, index) => {
        x.index = (index + 1)+'.';
        return x;
      })
    },

    account() {
      return this.$store.state.activeAccount;
    },

    formTitle () {
      return this.editedIndex === -1 ? 'Add new contact' : 'Edit contact';
    },

    bformTitle () {
      return this.editedBroadcastIndex === -1 ? 'Create new broadcast list' : 'Edit broadcast list';
    },

    numberRules() {
      if (this.selectedCountry) {
        const length = (this.selectedCountry.minNumberLength || 10);
        return [
          v => (!!v && !isNaN(this.editedItem.mobile) && this.editedItem.mobile.length >= length) || 'Mobile number is required',
        ]
      }
      return this.validationRules.mobileRules;
    },

    pcolor() {
      return randomColor();
    }
  },

  watch: {
    'search':  _.debounce(function(nv) {
      this.fetchContacts(1, this.options.itemsPerPage, nv)
    }, 500),

    options: {
      handler(options) {
        if (this.firstLoad) this.fetchContacts(options.page, options.itemsPerPage, this.search);
      },
      deep: true,
    },

    labelDialog(n) {
      if (n) this.resetColor(true);
    },

    'filters.labels'() {
      this.fetchContacts(1);
    },

    'bsearch':  _.debounce(function(nv) {
      this.fetchBroadcasts(1, this.boptions.itemsPerPage, nv)
    }, 500),

    boptions: {
      handler(options) {
        if (this.firstLoad) this.fetchBroadcasts(options.page, options.itemsPerPage, this.bsearch);
      },
      deep: true,
    },

    'bfilters.labels'() {
      this.fetchBroadcasts(1);
    },

    psearch(newVal, oldVal) {
      if (newVal && newVal != oldVal && newVal != this.lastSearched && (this.selectedItem && newVal != this.selectedItem.name)) this.querySelections(newVal);
    },

    contactModified(n) {
      if (n) {
        const cobj = this.contactsObject;
        this.fetchContacts(cobj.pageNumber, cobj.itemsPerPage, this.search); 
        this.contactModified = false;
      }
    }
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      _id: '',
      loading: false,
      processing: false,
      firstLoad: false,
      gnloading: false,

      gprocessing: false,
      google: false,

      gcfyprocessing: false,
      gcfyDialog: false,

      lloading: false,
      labels: [],

      search: '',
      contacts: [],
      contactsObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 15
      },
      filters: {
        labels: [],
      },

      dialog: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        deactivated: false
      },
      contactModified: false,

      labelDialog: false,
      editedLabelIndex: -1,
      editedLabelItem: {},
      defaulLabeltItem: {
        deleted: false,
        color: randomColor()
      },

      options: {},
      confirmDialog: false,
      beingDeleted: {},

      bsearch: '',
      bloading: false,
      boptions: {},
      broadcasts: [],
      broadcastsObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10
      },
      bfilters: {
        labels: [],
      },

      psearch: '',
      ploading: false,
      pprocessing: false,
      recipients: [],

      lastSearched: '',
      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,
      selectedItem: {},
      pheaders: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Mobile', value: 'mobile' },
        { text: 'Remove', value: 'actions', sortable: false, align: 'center'},
      ],

      cloading: false,
      broadcastDialog: false,
      editedBroadcastIndex: -1,
      editedBroadcastItem: {
        deleted: false,
        isActive: true,
        recipients: []
      },
      defaultBroadcastItem: {
        deleted: false,
        isActive: true,
        recipients: []
      },

      headers: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Mobile', value: 'mobile' },
        { text: 'Status', value: 'deactivated' },
        { text: 'Created', value: 'createdAt' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ],

      bheaders: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Recipients', value: 'totalRecipients' },
        { text: 'Status', value: 'isActive' },
        { text: 'Created', value: 'createdAt', align: 'right', sortable: false },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ],

      validationRules: {
        mobileRules: [
          v => !!v || 'Mobile number is required',
          v => /^[0]?[56789]\d{9}$/.test(v) || 'Please enter a valid mobile number'
        ],
        nameRule: [
          v => !!v || 'Name is required'
        ],
        basicRule: [
          v => !!v || 'Required'
        ],
        countryRule: [
          v => !!v || 'Country is required'
        ],
        statusRule: [
          v => !!v || v == false || 'Status is required'
        ],
        emailRule: [
          v => !v || v && emailregex.test(v) || 'Must be a valid email'
        ],
        lengthRule: [
          v => !!v.length || 'One or more recipients are required'
        ],
      }
    }
  }
}