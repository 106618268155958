import moment from 'moment';
import Account from '../../../services/account';

import Misc from '../../../services/misc';

export default {
  name: 'AccountSettings',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');

    this.account = this.$store.state.activeAccount;
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    saveSettings() {
      this.processing = true;
      Misc.snackbarStart();

      const object = { 
        historyDisabled: this.account.historyDisabled,
        isGrowcifyCustomer: this.account.isGrowcifyCustomer
      };

      return Account
        .editAccount(this._id, object, false)
        .then(() => {
          this.editMode = false;
          this.processing = false;
          Misc.snackbarSuccess();

          this.$store.state.activeAccount.historyDisabled = object.historyDisabled;
          this.$store.state.activeAccount.isGrowcifyCustomer = object.isGrowcifyCustomer;
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
        })
    },

    copy(content) {
      navigator.clipboard.writeText(content);
    },

    saveWebhook() {
      if (!this.$refs.wform.validate()) return;
      const object = { url: this.account.webhookURL };

      Misc.snackbarStart();
      this.wprocessing = true;
      return Account
        .setWebhook(this._id, object, false)
        .then(() => {
          this.wprocessing = false;
          Misc.snackbarSuccess();
          this.$store.state.activeAccount.webhookURL = object.url;
        })
        .catch(() => {
          this.wprocessing = false;
          Misc.snackbarError();
        })
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    accSecret() {
      if (!this.account || !this.account.accSecret) return '';
      const secret = this.account.accSecret;
      const length = secret.length;
      const array = new Array(length).fill('*');
      return array.join('');
    }
  },

  data() {
    const validWebsite = (value) => {
      return value.includes('https://');
    }
    
    return {
      _id: '',
      loading: true,
      processing: false,
      wprocessing: false,

      account: {},
      editMode: false,

      validationRules: {
        websiteRule: [
          v => v && validWebsite(v) || 'Must be a valid website'
        ]
      }
    }
  }
}