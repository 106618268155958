import Vue from 'vue';

Vue.component('ConfirmDialog', require('./components/confirm-dialog/confirm-dialog.vue').default);
Vue.component('TextWrapper', require('./components/text-wrapper/text-wrapper.vue').default);
Vue.component('MiniLoader', require('./components/mini-loeader/mini-loeader.vue').default);
Vue.component('PremiumBanner', require('./components/premium-banner/premium-banner').default);


export default {
  name: 'App',
  
  computed: {
    isLoginSignup() { return ['login', 'signup', 'signin', 'businesses'].includes(this.$route.name) },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    isInbox() {
      return this.$route.name == 'account-inbox';
    }
  },

  watch: {
    '$vuetify.theme.dark'(n) {
      let color = 'auto';
      let bgcolor = 'white';
      if (n) { color = '#121212 #1e1e1e'; bgcolor = '#1e1e1e' }
      document.getElementById('mhtml').style['scrollbar-color'] = color;
      document.getElementById('mhtml').style['background-color'] = bgcolor;
    }
  },

  mounted() {
    // const theme = localStorage.getItem('dark');
    // if (theme) {
    //   if (theme == 'true') this.$vuetify.theme.dark = true;
    //   else this.$vuetify.theme.dark = false;
    // }
  },

  data() {
    return {
      dloading: false,
      dsActive: false,
    }
  }
}