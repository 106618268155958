import moment from 'moment';

import Campaign from '../../../services/campaign';

import TemplateContent from '../../../components/template-content/template-content.vue';

export default {
  name: 'Campaign',

  created() {
    this._id = this.$route.params._id;
    this.campaignId = this.$route.params.campaignId;
    if (!this._id || !this.campaignId) return this.navigate('home');

    this.fetchCampaign();
    this.fetchMessages()
  },

  components: {
    TemplateContent
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    refresh() {
      this.fetchCampaign();
      this.fetchMessages();
    },

    fetchCampaign() {
      this.loading = true;
      return Campaign
        .getCampaign(this.campaignId, false)
        .then(x => {
          this.campaign = x.data || {};
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        })
    },

    fetchMessages() {
      this.mloading = true;
      return Campaign
        .getCampaignMessages(this.campaignId, false)
        .then(x => {
          this.messages = x.data || [];
          this.mloading = false;

          this.read = this.messages.filter(m => m.status == 'read').length;
          this.delivered = this.messages.filter(m => m.status == 'delivered').length;

          this.delivered = this.delivered + this.read;
        })
        .catch(e => {
          console.log(e);
          this.mloading = false;
        })
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },
  },

  computed: {
    account() {
      return this.$store.state.activeAccount || false;
    },

    indexed() {
      return this.messages.map((x, index) => {
        x.index = (index + 1)+'.';
        x.name = x.contact.name;
        x.mobile = '+'+x.contact.callingCode +' '+x.contact.mobile;
        return x;
      })
    }
  },

  data() {
    return {
      _id: '',
      campaignId: '',

      loading: false,
      mloading: false,

      campaign: {
        template: {}
      },

      search: '',
      messages: [],
      delivered: 0,
      read: 0,

      headers: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Mobile', value: 'mobile' },
        { text: 'Status', value: 'status' },
        { text: 'Updated At', value: 'updatedAt', align: 'right', sortable: false },
      ],
    }
  }
}