<template>
  <div class="tooltip-container">
    <slot />
    <div class="tooltip light-shadow2 animate__animated animate__fadeIn">
      <v-row>
        <v-col cols="12">
          <h5 class="mb-2 mt-1">{{ title }}</h5>
          <small>{{ description }}</small>
        </v-col>

        <v-col class="text-right pt-0 pr-5 pb-5">
          <v-btn outlined icon x-small color="primary" @click="redirect"><v-icon x-small>mdi-help</v-icon></v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>

.tooltip-container { 
  position: relative;
  display: inline-block;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  transition: opacity 1s;
  display: block;
}

.tooltip { 
  display: none;
  text-align: left;
  padding: 15px;
  border-radius: 12px;

  min-width: 325px;
  top: 100%;
  left: 50%;
  margin-left: -150px;

  opacity: 0;
  transition: opacity 1s;

  position: absolute;
  z-index: 1;

  background: #ffffff;
  white-space:pre-wrap;
}
</style>

<script>
export default {
  name: 'InfoTooltip',

  props: [
    'title',
    'description',
    'link'
  ],

  methods: {
    redirect() {
      window.open(this.to, '_blank');
    }
  },

  computed: {
    to() {
      if (this.link == 'l1') return this.l1;
      if (this.link == 'l3') return this.l3;
      return this.l2;
    }
  },

  data() {
    return {
      l1: 'https://developers.facebook.com/docs/whatsapp/pricing',
      l2: 'https://developers.facebook.com/docs/whatsapp/pricing#free-tier-conversations',
      l3: 'https://developers.facebook.com/docs/whatsapp/pricing#free-entry-points-conversations'
    }
  }
}
</script>