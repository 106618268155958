import Misc from '../../services/misc';

export default {
  name: 'TemplateHeader',

  props: [
    '_id',
    'template',
    'uploader',
    'title'
  ],

  created() {
    const components = this.template.components;
    if (components) {
      const header = components.find(x => x.type == 'HEADER');
      if (header) this.header = header;
    }
  },

  methods: {
    getFormatted(text) {
      // bold check
      const bold = /\*(.*?)\*/gm;
      text = text.replace(bold, '<b>$1</b>');            

      const italic = /_(.*?)_/gm;
      text = text.replace(italic, '<i>$1</i>'); 

      // strike check
      const strike = /~(.*?)~/gm;
      text = text.replace(strike, '<s>$1</s>'); 

      return text;
    },

    parsed(text) {
      const ivariables = this.template.variables;
      if (!ivariables || !ivariables.length) return text;

      const regExp = /{{([^}]+)}}/g;
      const mvariables = [];

      let varMatch;
      while ((varMatch = regExp.exec(text))) {
        mvariables.push(varMatch[0]);
      }

      mvariables.forEach(x => {
        text = text.replace(x, ivariables[x]);
      });

      return text;
    },

    getMediaRef(type) {
      if (type == 'image') return this.$refs.imgPicker;
      if (type == 'video') return this.$refs.videoPicker;
      if (type == 'document') return this.$refs.documentPicker;
    },

    initUpload(type = 'image') {
      this.getMediaRef(type).click();
    },

    throwError(message) {
      this.$store.state.snackbar.text = message;
      this.$store.state.snackbar.show = true;
      this.$store.state.snackbar.color = 'error';
    },

    isValidMedia(file, type) {
      if (file && (file['type'].split('/')[0] == type)) return true;
      if (type == 'document') return true;
      return false;
    },

    mediaSelected(type) {
      const file = this.getMediaRef(type).files[0];
      const isValid = this.isValidMedia(file, type);
      if (!isValid) return this.throwError('Please select a valid '+type+' file.');

      this.file = file;
      this.uploadMedia(type, file);
    },

    uploadMedia(type, file) {
      this.uploading = true;
      return Misc
        .uploadMedia(this._id, type, file)
        .then(x => {
          if (x.data.ok) {
            this.media = x.data.data.id;
            this.processHeaderComponent();
          }
          else this.throwError('Uploading failed, please try again');
          this.uploading = false;
        })
        .catch(e => {
          console.log(e);
          this.throwError('Uploading failed, please try again');
          this.uploading = false;
        });
    },

    processHeaderComponent() {
      const header = this.header;
      if (header.format == 'DOCUMENT') {
        header.document = { id: this.media, filename: this.file.name };
      }

      else if (header.format == 'IMAGE') {
        header.image = { id: this.media };
      }

      else if (header.format == 'VIDEO') {
        header.video = { id: this.media };
      }

      this.template.components.forEach(x => {
        if (x.type == 'HEADER') x = header;
      });

      this.$emit('update:headerMediaUploaded', true);
    },
  },

  data() {
    return {
      header: false,
      uploading: false,
      media: '',
      file: ''
    }
  }
}