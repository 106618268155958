<template>
  <div :class="mclass">
    <div v-if="!msg.file" style="width: 50%; margin: auto;">
      <v-row>
        <v-col>
          <v-btn :loading="msg.loading" icon x-large style="margin-top: -20px;" @click="download">
            <v-icon large color="white">{{ micon }}</v-icon>
          </v-btn>
          <div>
            <small style="color: var(--indull)">
              {{ msg.loading ? 'downloading...' : 'click to download' }}
            </small>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-icon v-else color="var(--indull)">{{ micon }}</v-icon>

    <MessageTimer v-if="showTimer" :msg="msg" :right="right"></MessageTimer>
  </div>
</template>
<script>
import Inbox from '../../services/inbox';
import MessageTimer from '../message-timer/message-timer.vue';

export default {
  name: 'MediaLoader',

  props: [
    'msg',
    '_id',
    'right',
    'dicon'
  ],

  components: {
    MessageTimer
  },

  methods: {
    async download() {
      const item = this.msg;
      if (item.file) return;
      let mediaId;

      if (item.type == 'image' && item.image) mediaId = item.image.id;
      else if (item.type == 'audio') mediaId = item.audio.id;
      else if (item.type == 'voice') mediaId = item.voice.id;
      else if (item.type == 'document') mediaId = item.document.id;
      else if (item.type == 'video') mediaId = item.video.id;
      else if (item.type == 'sticker') mediaId = item.sticker.id;

      item.loading = true;
      const mresponse = await this.getMedia(mediaId);
      const rdata = mresponse.data;
      if (rdata.ok) item.file = rdata.data;
      item.loading = false;

      if (item.type == 'document') this.$parent.downloadDocument();
    },

    getMedia(mediaId) {
      return Inbox.getMedia(this._id, mediaId)
    },
  },

  computed: {
    mclass() {
      let cname = 'lmedia';
      if (['voice', 'audio'].includes(this.msg.type)) cname = 'lamedia';
      else if (this.msg.type == 'document') return 'ldmedia';
      return cname;
    },

    pclass() {
      let cname = 'mprogress';
      if (['voice', 'audio'].includes(this.msg.type)) cname = 'maprogress';
      else if (this.msg.type == 'document') cname = 'mdprogress';
      return cname;
    },

    micon() {
      if (!this.msg) return;
      let icon = 'mdi-image';
      if (this.msg.type == 'sticker') icon = 'mdi-sticker';
      else if (this.msg.type == 'document') icon = this.dicon;
      else if (this.msg.type == 'video') icon = 'mdi-play';
      else if (this.msg.type == 'audio') icon = 'mdi-headphones';
      else if (this.msg.type == 'voice') icon = 'mdi-microphone';

      return icon;
    },

    showTimer() {
      if (!this.msg) return false;

      if (this.msg.type != 'image') return true;
      if (this.msg.image && this.msg.image.caption) return false;
      return true;
    }
  },

  data() {
    return {
    }
  }
}
</script>