import Vue from "vue";

export default {
  sendOTP(account, noProgress = true) {
    return Vue.axios.post(`account/send-otp`, account, {
      noProgress: noProgress,
    });
  },

  verifyOTP(account, noProgress = true) {
    return Vue.axios.post(`account/verify-otp`, account, {
      noProgress: noProgress,
    });
  },

  getAllAccounts(noProgress = true) {
    return Vue.axios.get(`account/list/all`, { noProgress: noProgress });
  },

  getNumbers(noProgress = true) {
    return Vue.axios.get(`account/numbers`, { noProgress: noProgress });
  },

  getAccounts(userId, noProgress = true) {
    return Vue.axios.get(`account/list/${userId}`, { noProgress: noProgress });
  },

  getAccount(accountId, noProgress = true) {
    return Vue.axios.get(`account/${accountId}`, { noProgress: noProgress });
  },

  createAccount(account, noProgress = true) {
    return Vue.axios.post(`account`, account, { noProgress: noProgress });
  },

  editAccount(accountId, account, noProgress = true) {
    return Vue.axios.put(`account/${accountId}`, account, {
      noProgress: noProgress,
    });
  },

  deleteAccount(accountId, noProgress = true) {
    return Vue.axios.delete(`account/${accountId}`, { noProgress: noProgress });
  },

  updateConfig(accountId, object, noProgress = true) {
    return Vue.axios.post(`account/config/${accountId}`, object, {
      noProgress: noProgress,
    });
  },

  setWebhook(accountId, object, noProgress = true) {
    return Vue.axios.post(`account/webhook/${accountId}`, object, {
      noProgress: noProgress,
    });
  },

  processPayment(accountId, object, noProgress = true) {
    return Vue.axios.post(`account/process-payment/${accountId}`, object, {
      noProgress: noProgress,
    });
  },

  renewSecret(accountId, noProgress = true) {
    return Vue.axios.put(
      `account/renew-secret/${accountId}`,
      {},
      { noProgress: noProgress }
    );
  },

  generateAPIKey(accountId, noProgress = true) {
    return Vue.axios.post(
      `account/generate-api-key/${accountId}`,
      {},
      { noProgress: noProgress }
    );
  },

  getBalance(accountId, filters, noProgress = true) {
    return Vue.axios.post(`account/balance/${accountId}`, filters, {
      noProgress: noProgress,
    });
  },

  changeProfilePhoto(accountId, body, noProgress = true) {
    return Vue.axios.post(`account/photo/${accountId}`, body, {
      noProgress: noProgress,
    });
  },

  changeProfileAbout(accountId, body, noProgress = true) {
    return Vue.axios.post(`account/about/${accountId}`, body, {
      noProgress: noProgress,
    });
  },

  changeBusinesProfile(accountId, body, noProgress = true) {
    return Vue.axios.post(`account/business-profile/${accountId}`, body, {
      noProgress: noProgress,
    });
  },

  getProfile(accountId, noProgress = true) {
    return Vue.axios.get(`account/profile/${accountId}`, {
      noProgress: noProgress,
    });
  },
};
