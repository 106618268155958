<template>
  <v-row v-if="account">
    <!-- <v-col cols="2">
      <v-avatar size="50px" outlined :color="image ? '' : 'primary'">
        <img v-if="image" @click="navigate('home')" :src="image">
        <span v-else class="white--text" style="font-size: 1.25rem;"><b>{{ account.brand | initials }}</b></span>
      </v-avatar>
    </v-col> -->

    <v-col class="pt-0 vc pb-0">
      <h3>{{ title }}</h3>
      <small>{{ account.company }}</small>
    </v-col>

    <!-- <v-col class="vc">
      <v-btn @click="testRequest" rounded color="primary" :loading="processing">
        Test Request
      </v-btn>
    </v-col> -->
  </v-row>
</template>

<script>
import config from "../../config";
import Misc from "../../services/misc";

export default {
  name: "SubHeader",

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    async testRequest() {
      this.processing = true;
      await Misc.testRequest();
      this.processing = false;
    },
  },

  computed: {
    account() {
      return this.$store.state.activeAccount || false;
    },

    image() {
      return this.account.image
        ? config.waeasyapiS3 + this.account.image
        : false;
    },

    route() {
      return this.$route.name;
    },

    isCampaigns() {
      return this.route == "account-campaigns";
    },

    title() {
      let title = "";
      if (this.route == "account-dashboard") title = "Dashboard";
      else if (this.route == "account-inbox") title = "Inbox";
      else if (this.route == "account-contacts") title = "Contacts";
      else if (this.route == "account-groups") title = "Groups";
      else if (this.route == "account-agents") title = "Agents";
      else if (this.route == "account-templates") title = "WhatsApp Templates";
      else if (this.route == "account-payments") title = "Payments";
      else if (this.route == "account-profile") title = "Business Profile";
      else if (this.route == "account-settings") title = "Settings";
      else if (this.route == "account-campaigns") title = "Campaigns";

      return title; //+ ' - ' + this.account.brand;
    },
  },

  data() {
    return {
      processing: false,
    };
  },
};
</script>
