
import User from '../../services/user';
import Misc from '../../services/misc';

export default {
  name: 'Profile',

  created() {
    this.profile.name = this.user.name;
    this.profile.email = this.user.email;

    this.callingCode = '+'+this.user.callingCode;
  },

  methods: {
    saveProfile() {
      if (!this.$refs.form.validate()) return;
      
      this.processing = true;
      Misc.snackbarStart();
      return User
        .editProfile(this.user._id, this.profile, false)
        .then(() => {
          this.$store.state.userInfo.name = this.profile.name;
          this.$store.state.userInfo.email = this.profile.email;

          this.editMode = false;
          this.processing = false;
          Misc.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Misc.snackbarError();
        });
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    }
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      processing: false,
      editMode: false,

      callingCode: '',
      profile: {
        name: '',
        email: ''
      },

      validationRules: {
        basicRule: [
          v => !!v || 'Required'
        ],

        emailRule: [
          v => v && emailregex.test(v) || 'Must be a valid email'
        ]
      },
    }
  }
}