import Vue from 'vue';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import VueApexCharts from 'vue-apexcharts'

import Account from '../../../services/account';

Vue.component('InfoTooltip', require('../../../components/info-tooltip/info-tooltip.vue').default);

//const todayDate = moment().format('YYYY-MM-DD');
const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

window.Apex.chart = { fontFamily: "'Jost', sans-serif" };

export default {
  name: 'AccountDashboard',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');
    if (this.$store.state.dfirstload) this.getBalance();
    this.$store.state.dfirstload = true;
  },

  components: {
    VueApexCharts
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    getSymbolFromCurrency(code) {
      return getSymbolFromCurrency(code);
    },

    getBalance() {
      this.loading = true;
      const filters = { dates: this.dates };
      return Account.getBalance(this._id, filters, false)
        .then(x => {
          this.balance = x.data; 
          this.loading = false;
          this.prepareCharts();
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        })
    },

    prepareCharts() {
      const categories = [];
      const months = this.stats.usage || [];
      const length = months.length;

      const freec = 'Free conversations';
      const freec1 = 'Free tier';
      const freec2 = 'Free entry point';

      const paidc = 'Paid conversations';
      const paidc1 = 'Business-initiated';
      const paidc2 = 'User-initiated';

      const forfree = [
        { name: freec, data: [0] },
        { name: freec1, data: [0] },
        { name: freec2, data: [0] }
      ];

      const common = [
        { name: paidc, data: [0] },
        { name: paidc1, data: [0] },
        { name: paidc2, data: [0] }
      ];

      this.freeConversations = JSON.parse(JSON.stringify(forfree));
      this.allConversations = JSON.parse(JSON.stringify(common));
      this.paidConversations = JSON.parse(JSON.stringify(common));
      this.approximateCharges = JSON.parse(JSON.stringify(common));

      const freeData1 = [], freeData2 = [], freeData3 = [];
      const allData1 = [], allData2 = [], allData3 = [];
      const paidData1 = [], paidData2 = [], paidData3 = [];
      const acData1 = [], acData2 = [], acData3 = [];

      if (!length) {
        categories.push(moment().format('MMM YY'));
        
        freeData1.push(0); freeData2.push(0); freeData3.push(0);
        allData1.push(0); allData2.push(0); allData3.push(0);
        paidData1.push(0); paidData2.push(0); paidData3.push(0);
        acData1.push(0); acData2.push(0); acData3.push(0);
      }

      for (let i = 0; i < length; i++) {
        const item = months[i];
        categories.push(moment(item.period_date).format('MMM YY'));
        if (i == 0) this.dates[0] = moment(item.period_date).format('YYYY-MM-DD');

        // 1 - process free convo
        freeData1.push(item.free_quantity);
        freeData2.push(item.free_tier);
        freeData3.push(item.free_entry_point);

        // 2 - process all convo
        allData1.push(item.quantity);
        allData2.push(item.business_initiated_quantity);
        allData3.push(item.user_initiated_quantity);

        // 3 - process paid convo
        paidData1.push(item.paid_quantity);
        paidData2.push(item.business_initiated_paid_quantity);
        paidData3.push(item.user_initiated_paid_quantity);

        // 4 - process approximate charges
        acData1.push(item.total_price);
        acData2.push(item.business_initiated_price);
        acData3.push(item.user_initiated_price);
      }

      

      this.freeConversations[0].data = freeData1;
      this.freeConversations[1].data = freeData2;
      this.freeConversations[2].data = freeData3;

      this.allConversations[0].data = allData1;
      this.allConversations[1].data = allData2;
      this.allConversations[2].data = allData3;

      this.paidConversations[0].data = paidData1;
      this.paidConversations[1].data = paidData2;
      this.paidConversations[2].data = paidData3;

      this.approximateCharges[0].data = acData1;
      this.approximateCharges[1].data = acData2;
      this.approximateCharges[2].data = acData3;

      this.chartOptions.xaxis.categories = categories;
    },
  },

  

  computed: {
    account() {
      return this.$store.state.activeAccount;
    },

    stats() {
      if (this.balance && this.balance.stats && Object.keys(this.balance.stats).length) return this.balance.stats;
      return false;
    },

    currency() {
      return this.stats.currency || 'EUR';
    },

    conversations() {
      return this.stats.usage && this.stats.usage.length ? this.stats.usage[this.stats.usage.length-1] : {} 
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    dateRangeText() {
      const date1 =  this.dates[0];
      const date2 = this.dates[1];
      return this.formatDate(date1) +' - '+this.formatDate(date2);
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? 'text-white' : 'text-black';
    },

    lcolor() {
      return this.isDT ? 'grey darken-4' : 'grey lighten-4'
    }
  },

  watch: {
    calModal(n) {
      if (!n && this.calDates && this.calDates.length == 2) {
        const d1 = this.calDates[0];
        const d2 = this.calDates[1];
        if (moment(d2).isBefore(d1)) {
          this.calDates[0] = d2;
          this.calDates[1] = d1;
        }
        this.dates = JSON.parse(JSON.stringify(this.calDates));
      }
    }
  },

  mounted() {
    const priceChartOptions = Object.assign({}, this.chartOptions);
    priceChartOptions.yaxis = {
      labels: {
        formatter: (value) => {
          return this.$options.filters.fcn(value, 'EUR');
        }
      },
    }
    this.priceChartOptions = priceChartOptions;
  },

  data() {
    return {
      _id: '',
      logo: require('@/assets/imgs/icon.png'),
      balance: false,
      loading: false,

      firstload: false,
      
      calMaxDate: new Date().toISOString().substr(0, 10),
      dates: [startOfMonth, endOfMonth],
      calDates: [startOfMonth, endOfMonth],
      calModal: false,
      fselection: false,

      payModal: false,

      chartOptions: {
        chart: {
          id: 'WAEasyAPI',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        colors: ['#075E54', '#128C7E', '#25D366'],
        xaxis: {
          categories: []
        }
      },

      priceChartOptions: {},

      allConversations: [],
      approximateCharges: [],
      paidConversations: [],
      freeConversations: []
    }
  }
}