import Contact from '../../services/contact';
import Misc from '../../services/misc';

export default {
  name: 'ContactForm',

  props: [
    '_id',
    'editedItem',
    'contactModified',
  ],

  computed: {
    formTitle () {
      return !this.editedItem._id ? 'Add new contact' : 'Edit contact';
    },

    numberRules() {
      if (this.selectedCountry) {
        const length = (this.selectedCountry.minNumberLength || 10);
        return [
          v => (!!v && !isNaN(this.editedItem.mobile) && this.editedItem.mobile.length >= length) || 'Mobile number is required',
        ]
      }
      return this.validationRules.mobileRules;
    },
  },

  methods: {
    saveContact() {
      if (!this.$refs.form.validate()) return;
      Misc.snackbarStart();

      if (this.selectedCountry._id) {
        this.editedItem.callingCode = this.selectedCountry.callingCode;
      }

      let Request;
      this.processing = true;
      const contact = this.editedItem;
      if (contact._id) Request = Contact.editContact(contact._id, contact, false);
      else Request = Contact.addContact(this._id, contact, false);

      return Request
        .then(() => {
          this.processing = false;
          Misc.snackbarSuccess();
          
          this.$store.state.contactFormModal = false;
          this.$emit('update:contactModified', true);
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
          this.$store.state.snackbar.text = 'Most likely contact already exists';
        })
    },

    closeContact() {
      this.$store.state.contactFormModal = false;
    },

    text(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    processDefaultCountry() {
      if (this.countries && this.countries.length) {
        const defaultCountry = this.countries.find(x => {
          if (x.callingCode === this.editedItem.callingCode) return x;
          if (!this.editedItem.callingCode && x.isDefault) return x;
        });
        if (defaultCountry) this.selectedCountry = defaultCountry;
      }
    },

    fetchCountries() {
      if (this.countries.length) return;

      const cs = this.$store.state.countries;
      if (cs && cs.length) {
        this.countries = cs;
        this.processDefaultCountry();
        return;
      }

      this.cfetching = true;
      return Misc.getCountries(false)
        .then(res => {
          this.countries = res.data;
          this.$store.state.countries = res.data;

          this.processDefaultCountry();
          this.cfetching = false;
        })
        .catch(e => { console.log(e); this.cfetching = false; });
    },

    fetchLabels() {
      if (this.labels.length) return;

      this.lloading = true;
      return Contact
        .getLabels(this._id, false)
        .then(x => {
          const data = x.data;
          this.labels = data;
          this.lloading = false;
        })
        .catch(e => {
          console.log(e);
          this.lloading = false;
        });
    },
  },

  watch: {
    '$store.state.contactFormModal'(n) {
      if (n) {
        this.fetchLabels();
        this.fetchCountries();
      }
    }
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      dialog: false,
      loading: false,
      processing: false,

      lloading: false,
      labels: [],

      selectedCountry: {},
      cfetching: false,
      countries: [],

      validationRules: {
        mobileRules: [
          v => !!v || 'Mobile number is required',
          v => /^[0]?[56789]\d{9}$/.test(v) || 'Please enter a valid mobile number'
        ],
        nameRule: [
          v => !!v || 'Name is required'
        ],
        basicRule: [
          v => !!v || 'Required'
        ],
        countryRule: [
          v => !!v || 'Country is required'
        ],
        statusRule: [
          v => !!v || v == false || 'Status is required'
        ],
        emailRule: [
          v => !v || v && emailregex.test(v) || 'Must be a valid email'
        ],
        lengthRule: [
          v => !!v.length || 'One or more recipients are required'
        ],
      }
    }
  }
}