import Vue from 'vue';

export default {
  getCampaigns(accountId, noProgress = true) {
    return Vue.axios.get(`campaign/list/${accountId}`, { noProgress: noProgress });
  },

  getCampaign(campaignId, noProgress = true) {
    return Vue.axios.get(`campaign/${campaignId}`, { noProgress: noProgress });
  },

  getCampaignMessages(campaignId, noProgress = true) {
    return Vue.axios.get(`campaign/messages/${campaignId}`, { noProgress: noProgress });
  },

  createCampaign(accountId, campaign, noProgress = true) {
    return Vue.axios.post(`campaign/${accountId}`, campaign, { noProgress: noProgress });
  },

  editCampaign(campaignId, campaign, noProgress = true) {
    return Vue.axios.put(`campaign/${campaignId}`, campaign, { noProgress: noProgress });
  },

  deleteCampaign(campaignId, noProgress = true) {
    return Vue.axios.delete(`campaign/${campaignId}`, { noProgress: noProgress });
  },

  getEnums(noProgress = true) {
    return Vue.axios.get(`campaign/enums`, { noProgress: noProgress });
  }
}