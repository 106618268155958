import Vue from 'vue';

export default {
  getAllCountries(noProgress = true) {
    return Vue.axios.get(`country/list/all`, { noProgress: noProgress });
  },

  getStates(noProgress = true) {
    return Vue.axios.get(`state/list/all`, { noProgress: noProgress });
  },

  getCities(noProgress = true) {
    return Vue.axios.get(`city/list/all`, { noProgress: noProgress });
  },

  addCountry(country, noProgress = true) {
    return Vue.axios.post(`country`, country, { noProgress: noProgress });
  },

  editCountry(countryId, country, noProgress = true) {
    return Vue.axios.put(`country/${countryId}`, country, { noProgress: noProgress });
  },

  deleteCountry(countryId, noProgress = true) {
    return Vue.axios.delete(`country/${countryId}`, { noProgress: noProgress });
  },

  //STATES
  addState(countryId, state, noProgress = true) {
    return Vue.axios.post(`state/${countryId}`, state, { noProgress: noProgress });
  },

  editState(stateId, state, noProgress = true) {
    return Vue.axios.put(`state/${stateId}`, state, { noProgress: noProgress });
  },

  deleteState(stateId, noProgress = true) {
    return Vue.axios.delete(`state/${stateId}`, { noProgress: noProgress });
  },

  //CITIES
  addCity(stateId, city, noProgress = true) {
    return Vue.axios.post(`city/${stateId}`, city, { noProgress: noProgress });
  },

  editCity(cityId, state, noProgress = true) {
    return Vue.axios.put(`city/${cityId}`, state, { noProgress: noProgress });
  },

  deleteCity(cityId, noProgress = true) {
    return Vue.axios.delete(`city/${cityId}`, { noProgress: noProgress });
  }
  
}