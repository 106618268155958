import moment from 'moment';

import config from '../../../config';
import Misc from '../../../services/misc';
import Account from '../../../services/account';

import ProfileTop from '../../../components/profile-top/profile-top.vue';

export default {
  name: 'AccountProfile',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');

    this.fetchProfile();
  },

  components: {
    ProfileTop
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    getURL(data) {
      return Misc.getImageURL(data);
    },

    fetchProfile() {
      this.loading = true;
      return Account.getProfile(this._id, false)
        .then(x => {
          this.profile = x.data;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    saveAbout() {
      Misc.snackbarStart();
      this.aprocessing = true;
      const object = { about: this.profile.about };
      return Account
        .changeProfileAbout(this._id, object, false)
        .then(() => {
          this.aprocessing = false;
          Misc.snackbarSuccess();
        })
        .catch(err => {
          console.log(err);
          this.aprocessing = false;
          Misc.snackbarError();
        })
    },

    saveBusinessProfile() {
      Misc.snackbarStart();
      this.bprocessing = true;
      const object = {
        vertical: this.profile.vertical,
        description: this.profile.description,
        email: this.profile.email,
        website: this.profile.website,
        address: this.profile.address
      };
      return Account
        .changeBusinesProfile(this._id, object, false)
        .then(() => {
          this.bprocessing = false;
          Misc.snackbarSuccess();
        })
        .catch(err => {
          console.log(err);
          this.bprocessing = false;
          Misc.snackbarError();
        })
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    account() {
      return this.$store.state.activeAccount;
    }
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;
    const validWebsite = (value) => {
      return value.includes('http://') || value.includes('https://');
    }

    return {
      _id: '',
      loading: true,
      processing: false,

      aprocessing: false,
      bprocessing: false,

      editMode: false,
      profile: {},

      wabaVerticals: config.wabaVerticals,

      validationRules: {
        basicRule: [
          v => !!v || 'Required'
        ],

        emailRule: [
          v => v && emailregex.test(v) || 'Must be a valid email'
        ],

        websiteRule: [
          v => v && validWebsite(v) || 'Must be a valid website'
        ],
      },
    }
  }
}