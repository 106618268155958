var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
  'text-right', _vm.isSticker ? (_vm.right ? 'mt-1' : 'mt-9') : '',
  _vm.right && _vm.isAudio && !_vm.msg.file ? 'mr-3' : '',
  _vm.right && _vm.isAudio ? 'pb-1 pr-1' : '',
  _vm.right && _vm.isImage ? 'pr-2' : '',
  _vm.right && _vm.isVideo ? 'mr-2' : '',
  _vm.isVideo && (_vm.msg.file || _vm.msg.video.link) && !_vm.msg.video.caption ? 'cvitmer' : '',
  _vm.isImage && (_vm.msg.file || _vm.msg.image.link) && !_vm.msg.image.caption ? 'mitimer' : '']},[(['text', 'template'].includes(_vm.msg.type))?_c('div',{class:['timer mt-1 mb-1', _vm.right ? 'd-inline' : '']},[_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v(_vm._s(_vm.formatTime(_vm.msg.createdAt)))])]):_c('div',{staticStyle:{"display":"inline-grid"}},[(_vm.msg.type == 'video')?_c('div',{staticClass:"vtimer",style:(_vm.right ? 'margin-right: 17px' : '')},[_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v(_vm._s(_vm.formatTime(_vm.msg.createdAt)))])]):_c('div',{class:['timer mb-1', _vm.tclass]},[_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v(_vm._s(_vm.formatTime(_vm.msg.createdAt)))])])]),(_vm.right)?_c('MessageTicker',{staticClass:"ml-1",attrs:{"right":_vm.right,"msg":_vm.msg}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }