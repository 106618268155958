import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

import config from '../../../config';

import Payment from '../../../services/payment';

export default {
  name: 'AccountPayments',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');
    
    this.fetchPayments();
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    fetchPayments() {
      this.loading = true;
      return Payment
        .getAccountPayments(this.user._id, this._id, false)
        .then(x => {
          const data = x.data || [];
          this.payments = data;
          this.allPayments = JSON.parse(JSON.stringify(data));
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    getSymbolFromCurrency(code) {
      return getSymbolFromCurrency(code);
    },

    exportPayments() {
      let endpoint = `${process.env.NODE_ENV !== 'production' ? config.apiDev : config.apiProd}/payment/export/${this.user._id}/${this._id}`;
      endpoint = endpoint + '?x-request-user-agent=application/admin-app&token=' + this.$store.state.token;
      window.open(endpoint, '_blank');
    },
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    indexed() {
      return this.payments.map((x, index) => {
        x.index = (index + 1)+'.';
        return x;
      })
    }
  },

  data() {
    return {
      _id: '',
      loading: true,
      payments: [],

      search: '',

      headers: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Account', value: 'account.company' },
        { text: 'Type', value: 'type' },
        { text: 'Amount', value: 'amount' },
        { text: 'Method', value: 'paymentMethod' },
        { text: 'Status', value: 'status' },
        { text: 'Created', value: 'createdAt' }
      ]
    }
  }
}