<template>
  <span v-if="msg" style="display: inline;">
    <v-progress-circular indeterminate :size="13" width="1.5" v-if="msg.uploading" small class="text-timer"></v-progress-circular>
    <v-icon v-else-if="msg.sending" small :class="['text-timer', iclass, vclass, vclass2, vclass3]">mdi-progress-clock</v-icon>
    <v-icon v-else-if="msg.status == 'read'" small :class="['text-read', iclass, vclass, vclass2, vclass3]">mdi-check-all</v-icon>
    <v-icon v-else-if="msg.status == 'delivered'" small :class="['text-timer', iclass, vclass, vclass2, vclass3]">mdi-check-all</v-icon>
    <v-icon v-else small style="margin-bottom: 0px;" :class="['text-timer', iclass, vclass, vclass2, vclass3]">mdi-check</v-icon>
  </span>
</template>
<script> 
export default {
  name: 'MessageTicker',

  props: ['msg', 'right'],

  computed: {
    isImage() {
      return this.msg.type == 'image';
    },

    isVideo() {
      return this.msg.type == 'video';
    },

    iclass() {
      if (!this.msg) return;
      
      let cname = '';
      if (this.right && this.isImage && !this.msg.file) {
        cname = 'cstm-riticker';
      }

      return cname;
    },

    vclass() {
      if (!this.msg) return;
      let cname = '';
      if (this.right && this.isVideo && (this.msg.file || this.msg.video.link) && this.msg.video.caption) {
        cname = 'cstm-rvticker'
      }
      return cname;
    },

    vclass2() {
      if (!this.msg) return;
      let cname = '';
      if (this.right && this.isVideo && this.msg.video.caption) {
        cname = 'cstm-rvticker2'
      }
      return cname;
    },

    vclass3() {
      if (!this.msg) return;

      let cname = '';
      if (this.right && this.isVideo && (this.msg.file || this.msg.video.link) && !this.msg.video.caption) {
        cname = 'cstm-rvticker3'
      }
      return cname;
    },
  }
}
</script>