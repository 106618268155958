import axios from 'axios';

import config from './config';
import Util from './services/util';

// create a new axios instance
const instance = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? config.apiDev : config.apiProd,
});

const agent = 'application/web-app';
const nonce = 'sKianQKk9r0A22Z-9rdIGATcvSW1E8mGZ0-6YZvcifo';
const control = 'deflate;age=5;js;waeasyapi/2022-0501;*/*-8YZicifo';

instance.defaults.headers.common['X-Request-User-Agent'] = agent;
instance.defaults.headers.common['X-Request-User-Nonce'] = Util.encrypt(nonce, nonce);
instance.defaults.headers.common['Accept-Control'] = Util.encrypt(control, control);

export default instance;