<template>
  <v-img v-if="image" :src="getURL(image)">
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0"  align="center" justify="center">
        <v-progress-circular :size="loaderSize || 25" indeterminate color="grey lighten-4"></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>
<script>
import Misc from '../../services/misc';

export default {
  name: 'ImageWithLoader',

  props: [
    'image',
    'loaderSize'
  ],

  methods: {
    getURL(data) {
      return Misc.getImageURL(data);
    },
  }
}
</script>