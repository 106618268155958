import Vue from 'vue';

export default {
  getAllPayments(noProgress = true) {
    return Vue.axios.get(`payment/list/all`, { noProgress: noProgress });
  },
  
  getPayments(userId, noProgress = true) {
    return Vue.axios.get(`payment/list/${userId}`, { noProgress: noProgress });
  },

  getAccountPayments(userId, accountId, noProgress = true) {
    return Vue.axios.get(`payment/list/${userId}/${accountId}`, { noProgress: noProgress });
  },

  addPayment(payment, noProgress = true) {
    return Vue.axios.post(`payment`, payment, { noProgress: noProgress });
  },

  editPayment(paymentId, payment, noProgress = true) {
    return Vue.axios.put(`payment/${paymentId}`, payment, { noProgress: noProgress });
  },

  deletePayment(paymentId, noProgress = true) {
    return Vue.axios.delete(`payment/${paymentId}`, { noProgress: noProgress });
  },

  getEnums(noProgress = true) {
    return Vue.axios.get(`payment/enums`, { noProgress: noProgress });
  }
}