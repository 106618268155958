import Vue from 'vue';

export default {
  getTemplates(accountId, noProgress = true) {
    return Vue.axios.get(`template/list/${accountId}`, { noProgress: noProgress });
  },

  syncTemplates(accountId, noProgress = true) {
    return Vue.axios.post(`template/sync/${accountId}`, {}, { noProgress: noProgress });
  },

  getEnums(accountId, noProgress = true) {
    return Vue.axios.get(`template/enums/${accountId}`, { noProgress: noProgress });
  },

  createTemplate(accountId, template, noProgress = true) {
    return Vue.axios.post(`template/${accountId}`, template, { noProgress: noProgress });
  },

  editTemplate(templateId, template, noProgress = true) {
    return Vue.axios.put(`template/${templateId}`, template, { noProgress: noProgress });
  },

  deleteTemplate(templateId, noProgress = true) {
    return Vue.axios.delete(`template/${templateId}`, { noProgress: noProgress });
  },

  gcfyTemplates(accountId, templates, noProgress = true) {
    return Vue.axios.post(`template/gcfy/${accountId}`, templates, { noProgress: noProgress });
  }
}