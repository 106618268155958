import moment from "moment";

import Misc from "../../services/misc";
import Account from "../../services/account";
import config, { new360Form } from "../../config";

import AccountBanner from "../../components/account-banner/account-banner.vue";

export default {
  name: "Accounts",

  created() {
    this.fetchAccounts();
  },

  components: {
    AccountBanner,
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    fetchAccounts() {
      this.loading = true;
      return Account.getAccounts(this.user._id, true)
        .then((x) => {
          const data = x.data || [];
          data.forEach((x) => (x.expanded = false));

          this.accounts = data;
          this.allAccounts = JSON.parse(JSON.stringify(data));
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    generateKey(item) {
      const email = item.email;
      const name = item.name;
      const company = item.company;
      let url = `${config.embeddedF360orm}?email=${email}&fullName=${name}&companyName=${company}`;
      if (item.key) url = config.dashboard360;
      window.open(url, "_blank");
    },

    facebookNamespace() {
      window.open(config.facebookNamespace, "_blank");
    },

    gotoAccount(item) {
      if (item.accessDisabled) return;

      if (item.inDraft) this.navigate("draft-account", { _id: item._id });
      else this.navigate("account-dashboard", { _id: item._id });
    },

    gotoInbox(item) {
      if (item.inDraft) this.navigate("draft-account", { _id: item._id });
      else this.navigate("account-inbox", { _id: item._id });
    },

    formatDate(date, format = "ll") {
      return moment(date).format(format);
    },

    generateAPIKey(item) {
      if (!item.channelID) {
        Misc.snackbarError();
        this.$store.state.snackbar.text = "Account is under processing.";
        return;
      }
      this.apiKeyConfirmItem = item;
      this.apiKeyConfirmModal = true;
    },

    finalGenerateAPIKey() {
      Misc.snackbarStart();
      this.processing = true;
      const accountID = this.apiKeyConfirmItem._id;
      return Account.generateAPIKey(accountID, true)
        .then((x) => {
          const data = x.data;
          if (data.ok) {
            Misc.snackbarSuccess();
            this.fetchAccounts();
          } else {
            Misc.snackbarError();
            this.$store.state.snackbar.text =
              "Something went wrong; try later.";
          }
          this.processing = false;
          this.apiKeyConfirmModal = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = true;
        });
    },

    enablePermissions() {
      const windowFeatures =
        "toolbar=no, menubar=no, width=600, height=900, top=100, left=100";
      window.open(new360Form, "integratedOnboardingWindow", windowFeatures);
    },

    updateAPIKey(item) {
      this.editedAccountItem = item;
      this.apiKeyModal = true;
    },

    renewSecret(item) {
      this.editedAccountItem = item;
      this.renewSecretModal = true;
    },

    copy(content) {
      navigator.clipboard.writeText(content);
      this.copyText = "Copied";
      setTimeout(() => (this.copyText = "Copy"), 1000);
    },

    saveAccount(type) {
      if (type == "apiKey" && !this.$refs.apiKeyForm.validate()) return;

      Misc.snackbarStart();
      this.processing = true;

      let Request;
      const account = this.editedAccountItem;
      if (type == "renewSecret")
        Request = Account.renewSecret(account._id, true);
      else if (type == "apiKey") {
        const object = { key: account.key, namespace: account.namespace };
        Request = Account.updateConfig(account._id, object, true);
      } else Request = Account.editAccount(account._id, account, true);

      return Request.then(() => {
        this.processing = false;
        this.apiKeyModal = false;
        this.renewSecretModal = false;
        Misc.snackbarSuccess();
        if (type == "renewSecret") this.fetchAccounts();
      }).catch(() => {
        this.processing = false;
        Misc.snackbarError();
      });
    },

    processRenewal(account) {
      const nthis = this;
      this.editedAccountItem = account;
      const amount = this.editedAccountItem.amount;
      const company = this.editedAccountItem.company;
      const currencyCode = this.editedAccountItem.currencyCode;
      const billingCycle = this.$options.filters.firstLetterUppercase(
        this.editedAccountItem.billingCycle
      );
      const description = `${company} - ${billingCycle} Subscription`;

      const options = Misc.getRazOptions(
        currencyCode,
        amount,
        description,
        this.user
      );
      options.handler = function (response) {
        nthis.processPayment(response.razorpay_payment_id);
      };
      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      if (!paymentId) {
        Misc.snackbarError();
        this.$store.state.snackbar.text = "Payment Unsuccessful";
        return;
      }

      Misc.snackbarStart();
      this.processing = true;

      const object = {
        paymentId: paymentId,
        amount: this.editedAccountItem.amount,
        currencyCode: this.editedAccountItem.currencyCode,
      };

      return Account.processPayment(this.editedAccountItem._id, object, false)
        .then(() => {
          this.processing = false;
          Misc.snackbarSuccess();
          this.fetchAccounts();
        })
        .catch((e) => {
          console.log(e);
          Misc.snackbarError();
          this.$store.state.snackbar.text =
            "Something went wrong; please contact our team";
        });
    },
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    activeAccounts() {
      return this.accounts.length
        ? this.accounts.filter((x) => x.isActive && x.hasPaid).length
        : 0;
    },

    inactiveAccounts() {
      return this.accounts.length
        ? this.accounts.filter((x) => x.inDraft || !x.isActive).length
        : 0;
    },

    darkDullColor() {
      return this.isDT ? "grey darken-3" : "grey lighten-3";
    },

    amISuperadmin() {
      return Misc.amISuperadmin();
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  watch: {
    keyword(n) {
      if (n)
        this.accounts = this.allAccounts.filter(
          (item) =>
            item &&
            (item.name.toString().toLowerCase().includes(n) ||
              item.company.toString().toLowerCase().includes(n) ||
              item.mobile.includes(n))
        );
      else this.accounts = this.allAccounts;
    },

    renewSecretModal(n) {
      if (!n) this.yesRead = false;
    },
  },

  data() {
    return {
      loading: true,
      accounts: [],
      keyword: "",

      mode: 1,

      processing: false,

      apiKeyModal: false,
      renewSecretModal: false,

      editedAccountItem: false,
      yesRead: false,

      apiKeyConfirmModal: false,
      apiKeyConfirmItem: {},

      copyText: "Copy",
      waeaBaseAPI: config.waeaBaseAPI,

      logo: require("@/assets/imgs/logo.png"),
      sapi: require("@/assets/imgs/screenshot-api.png"),

      validationRules: {
        basicRule: [(v) => !!v || "Required"],
      },
    };
  },
};
