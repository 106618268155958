// You still need to register Vuetify itself
// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { 
  VMain,
  VForm, 
  VInput, 
  VTextField,
  VCheckbox,
  VAutocomplete,
  VSelect,
  VDatePicker,
  VSpacer,
  VBtn,
  VDialog,
  VListItem,
  VListItemTitle,
  VListItemContent,
  VListItemAvatar,
  VListItemSubtitle,
  VImg,
  VProgressCircular,
  VBottomSheet,
  VSheet,
  VTimePicker,
  VContent,
  VApp,
  VProgressLinear,
  VTextarea,
  VOverlay,
  VContainer,
  VDivider,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VRow,
  VCol,
  VCarousel,
  VCarouselItem,
  VChip,
  VSkeletonLoader,
  VHover,
  VAlert,
  VAvatar,
  VTabs,
  VTabsItems,
  VTabItem,
  VTab,
  VRadioGroup,
  VRadio,
  VStepper,
  VStepperStep,
  VStepperContent,
  VList,
  VSubheader,
  VListItemGroup,
  VListItemAction,
  VListItemActionText,
  VFooter,
  VIcon,
  VNavigationDrawer,
  VListItemIcon,
  VToolbar,
  VToolbarTitle,
  VToolbarItems,
  VColorPicker,
  VFileInput,
  VSystemBar,
  VAppBar,
  VAppBarNavIcon,
  VCardSubtitle,
  VDataTable,
  VMenu,
  VSwitch,
  VStepperHeader,
  VTooltip,
  VStepperItems,
  VCombobox,
  VSnackbar,
  VBadge,
  VSimpleCheckbox,
  VSimpleTable,
  VListGroup,
  VBtnToggle,
  VEditDialog,
  VThemeProvider,
  VExpandTransition,
  VPagination,
  VExpansionPanels,
  VExpansionPanelHeader,
  VExpansionPanel,
  VExpansionPanelContent,
  VOtpInput,
  VSpeedDial,
  VVirtualScroll,
  VScrollXTransition,
  VSlideYTransition
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VMain,
    VForm,
    VInput,
    VCheckbox,
    VTextField,
    VAutocomplete,
    VSelect,
    VDatePicker,
    VSpacer,
    VBtn,
    VDialog,
    VListItem,
    VListItemTitle,
    VListItemContent,
    VListItemAvatar,
    VListItemSubtitle,
    VImg,
    VProgressCircular,
    VBottomSheet,
    VSheet,
    VTimePicker,
    VContent,
    VApp,
    VProgressLinear,
    VTextarea,
    VOverlay,
    VContainer,
    VDivider,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VRow,
    VCol,
    VCarousel,
    VCarouselItem,
    VChip,
    VSkeletonLoader,
    VHover,
    VAlert,
    VAvatar,
    VTabs,
    VTabsItems,
    VTabItem,
    VTab,
    VRadioGroup,
    VRadio,
    VStepper,
    VStepperStep,
    VStepperContent,
    VList,
    VSubheader,
    VListItemGroup,
    VListItemAction,
    VListItemActionText,
    VFooter,
    VIcon,
    VNavigationDrawer,
    VListItemIcon,
    VToolbar,
    VToolbarTitle,
    VToolbarItems,
    VColorPicker,
    VFileInput,
    VSystemBar,
    VAppBar,
    VAppBarNavIcon,
    VCardSubtitle,
    VDataTable,
    VMenu,
    VSwitch,
    VStepperHeader,
    VTooltip,
    VStepperItems,
    VCombobox,
    VSnackbar,
    VBadge,
    VSimpleCheckbox,
    VSimpleTable,
    VListGroup,
    VBtnToggle,
    VEditDialog,
    VThemeProvider,
    VExpandTransition,
    VPagination,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanel,
    VExpansionPanelContent,
    VOtpInput,
    VSpeedDial,
    VVirtualScroll,
    VScrollXTransition,
    VSlideYTransition
  }
})

const opts = { 
  theme: { },
}

export default new Vuetify(opts)