import Account from '../../../services/account';

export default {
  name: 'AccountInit',

  mounted() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');
    this.fetchAccount();
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    fetchAccount() {
      this.$store.state.aloading = true;
      return Account
        .getAccount(this._id)
        .then(x => {
          this.$store.state.activeAccount = x.data || {};
          this.$store.state.activeAccount.mute = false;
          this.$store.state.aloading = false;
        })
        .catch(() => {
          this.$store.state.aloading = false;
          this.navigate('home');
        })
    }
  },

  computed: {
    account() {
      return this.$store.state.activeAccount;
    },

    aloading() {
      return this.$store.state.aloading;
    },

    isInbox() {
      return this.$route.name == 'account-inbox';
    }
  },

  data() {
    return {
      _id: ''
    }
  }
}