import Vue from 'vue'
import Vuex from 'vuex'
import Pusher from 'pusher-js';
import router from './router';

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,

  state: {
    uuid: '',
    userInfo: {},
    
    dfirstload: false,
    aloading: false,
    activeAccount: {},

    authenticated: false,
    token: localStorage.getItem('token') || '',
    isMobile: window.innerWidth < 600,

    showBanner: true,
    isLoggedOut: false,
    isLoggedIn: false,

    cs: '',
    country: { 
      name: 'United States of America',
      countryCode: 'USA',
      callingCode: '1',
      currencySymbol: '$', 
      correncyCode: 'USD' 
    },

    notification: {
      duration: 3000,
      show: false,
      text: ''
    },

    snackbar: {
      timeout: 2500,
      show: false,
      text: '',
      color: 'success',
      btnColor: 'white',
      closeBtnText: 'Close',

      submittingText: 'Submitting...',
      submittedText: 'Your changes have been saved.',
      errorText: 'Your changes could not be saved.',
    },

    commonEnums: undefined,
    pricingEnums: {},
    countries: [],

    logingOut: false,
    superloading: false,
    gcsRequest: false,
    gcfyRequest: false,
    mute: false,
    
    rcInfo: false, //could be object - redirected from info

    labelEditorModal: false,
    contactFormModal: false,
    templateHeaderModal: false,

    pusher: new Pusher('c46161537a75ba826d68', { cluster: 'ap2' }),
    pusherInstanceEvents: [],
  },

  mutations: {
    authSuccess(state, infoObject) {
      const token = infoObject.token;
      delete infoObject.token;

      state.authenticated = true;
      state.token = token;
      state.userInfo = infoObject;
      state.uuid = infoObject._id;

      //store token in the localStorage and headers
      localStorage.setItem('token', token);
      Vue.axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

      if ((state.userInfo.roles.includes('superadmin') || state.userInfo.roles.includes('waeasyapi-employee'))) router.push({ name: 'super-dashboard' }).catch(() => {});
      else router.push({ name: 'home' }).catch(() => {});
    },
    
    authError(state) {
      state.authenticated = false;
      state.token = state.uuid = '';
      state.userInfo = {};
    },

    verifySuccess(state, infoObject) {
      state.authenticated = true;
      state.userInfo = infoObject;
      state.uuid = infoObject._id;
    },

    logout(state) {
      state.logingOut = true;
      return new Promise((resolve, reject) => {
        return Vue.axios.post('auth/logout/'+state.uuid+'/web')
          .then(() => {
            state.token = '';
            state.authenticated = false;
            localStorage.removeItem('token');
            state.isLoggedOut = true;
            state.logingOut = false;
            router.push('/signin');
            resolve();
            setTimeout(() => { state.isLoggedOut = false; }, 1000);

            //unsubscribe to channels; events
            if (state.activeInstance._id) state.pusher.unsubscribe(state.activeInstance._id);
            state.pusher.unsubscribe('waeasyapi-team-dashboard');
          })
          .catch(err => {
            reject(err)
            state.logingOut = false;
          })
      });
    },

    unset(state) {
      state.isLoggedOut = state.isLoggedIn = false;
    },

    loggedIn(state) {
      state.isLoggedIn = true;
      setTimeout(() => { state.isLoggedIn = false }, 2500);
    },
  },

  actions: {
    // eslint-disable-next-line
    sendOTP({commit}, uobj) {
      return new Promise((resolve, reject) => {

        const user = Object.assign({}, uobj);
        let endpoint = 'auth/send-otp';
        if (user.storeId) {
          endpoint += '/'+user.storeId;
          delete user.storeId;
        }

        Vue.axios.post(endpoint, uobj)
          .then(resp => resolve(resp))
          .catch(err => reject(err))
      })
    },

    verifyOTP({commit}, uobj) {
      return new Promise((resolve, reject) => {

        const user = Object.assign({}, uobj);
        let endpoint = 'auth/verify-otp';
        if (user.storeId) {
          endpoint += '/'+user.storeId;
          delete user.storeId;
        }

        Vue.axios.post(endpoint, user)
          .then(resp => {
            commit('authSuccess', resp.data);
            commit('loggedIn');
            resolve(resp)
          })
          .catch(err => {
            localStorage.removeItem('token');
            commit('authError');
            reject(err)
          })
      })
    },
    
    verify({commit}) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('auth/verify')
          .then(resp => {
            commit('verifySuccess', resp.data);
            resolve(resp);
          })
          .catch(err => {
            localStorage.removeItem('token');
            commit('authError');
            reject(err)
          })
      })
    }
  },

  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authenticated,
    userInfo: state => state.userInfo,
    isTokenAvailable: () => localStorage.getItem('token')
  }
});

export default store;