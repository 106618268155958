<template>
  <v-dialog v-model="confirmDialog" max-width="350" persistent color="error">
    <v-progress-linear v-if="processing" indeterminate color="primary" class="mb-0"></v-progress-linear>
    <v-card>
      <div class="pt-5 pl-6 pb-5">
        <div class="mt-2"><h3>Are you sure?</h3></div>
      </div>

      <v-card-text class="mb-3">
        <span>
          Are you sure you want to delete <b>{{ beingDeleted.name || beingDeleted.mobile || beingDeleted.named || beingDeleted.code || beingDeleted.title || beingDeleted.slot || beingDeleted.module || beingDeleted.key || beingDeleted.amount || beingDeleted._id }}</b>?
        </span>
      </v-card-text>

      <v-card-actions style="padding-bottom: 20px; padding-right: 25px; padding-left: 25px">
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" :disabled="processing" text @click="$parent.closeConfirm">No</v-btn>
      <v-btn color="green darken-1" :disabled="processing" text @click="$parent.finalDelete">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  export default {
    props: [
      'confirmDialog',
      'beingDeleted',
      'processing'
    ]
  }
</script>