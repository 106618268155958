<template>
  <section @click="copy" v-if="text">
    <span v-if="!hideTooltip">
      <v-tooltip v-if="computedtxt && computedtxt.length > length" bottom :max-width="maxWidth" style="border-radius: 25px !important;">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" v-html="computedtxt.length >= length ? computedtxt.substring(0, length - 3) + '...' : computedtxt">
          </span>
        </template>
        <span style="white-space: pre-wrap;" v-html="tooltip"></span>
      </v-tooltip>
      <span v-else v-html="tooltip"></span>
    </span>

    <span v-else>
      <span v-html="computedtxt.length >= length ? computedtxt.substring(0, length - 3) + '...' : computedtxt"></span>
    </span>
  </section>
</template>

<script>
export default {
  name: 'TextWrapper',

  props: [
    'text',
    'length',
    'copyDisabled',
    'hideTooltip',
    'width'
  ],

  computed: {
    tooltip() {
      return this.text && this.text.length > this.length ? (this.copiedText || this.text) : this.text;
    },

    maxWidth() {
      return this.width || 500;
    },

    computedtxt() {
      return this.text.replaceAll('\n', ' ');
    }
  },

  methods: {
    copy() {
      if (this.copyDisabled) return;
      navigator.clipboard.writeText(this.text);
      this.copiedText = 'Copied';
      setTimeout(() => this.copiedText = false, 1000);
    }
  },

  data() {
    return { 
      copiedText: false
    }
  }
}
</script>