<template>
  <v-row>
    <v-col v-if="!noPadding" cols="2"></v-col>
    <v-col>
      <div class="pmessage-div">
        <div class="msg-content pt-5 pb-5">
          <div class="talk-bubble-left tri-right left-top light-shadow3" style="margin-right: 10%">
            <div class="talktext">
              <div v-for="(item, index) in previewItem.components" :key="index" class="component-item">
              
                <div v-if="item.type == 'HEADER'" class="header">
                  <div v-if="item.format == 'TEXT'" class="pheader-text mb-2">
                    <h4 v-html="parseVariables ? parsed(getFormatted(item.text), true) : getFormatted(item.text)"></h4>
                  </div>

                  <div v-else-if="item.format == 'IMAGE'" class="pheader-media">
                    <v-icon x-large :color="icolor" style="font-size: 5rem; width: 250px;">mdi-image</v-icon>
                  </div>

                  <div v-else-if="item.format == 'VIDEO'" class="pheader-media">
                    <v-icon x-large :color="icolor" style="font-size: 5rem; width: 250px;">mdi-play-circle</v-icon>
                  </div>

                  <div v-else-if="item.format == 'DOCUMENT'" class="pheader-media">
                    <v-icon x-large :color="icolor" style="font-size: 5rem; width: 250px;">mdi-file-document</v-icon>
                  </div>
                </div>

                <div v-if="item.type == 'BODY'" class="pmessage-body">
                  <span 
                    class="wrap"
                    v-html="parseVariables ? parsed(getFormatted(item.text)) : getFormatted(item.text)">
                    </span>
                </div> 

                <div v-if="item.type == 'FOOTER'" 
                  class="pmessage-footer" 
                  v-html="getFormatted(item.text)"></div>
              </div>
              
              <div class="timer mt-1 mb-1">
                <span>{{ formatTime() }}</span>
              </div>
            </div>
          </div>

          <div v-if="buttonComponent" class="pmessage-buttons ml-5 mb-2">
            <v-row v-for="(item, index) in buttonComponent.buttons" :key="index">
              <v-col v-if="item.type == 'PHONE_NUMBER'" cols="12" class="pl-7 pr-9 pb-1">
                <a :ahref="'tel:'+item.phone_number+''">
                  <v-btn block color="white" class="nott elevation-0 pt-1 pb-1" style="border-radius: 10px; color: #31b6f6">
                    <v-icon left color="#31b6f6">mdi-phone</v-icon> <b>{{ item.text }}</b>
                  </v-btn>
                </a>
              </v-col>

              <v-col v-else-if="item.type == 'URL'" cols="12" class="pl-7 pr-9 pb-1">
                <a :href="item.url" target="_blank">
                  <v-btn block color="white" class="nott elevation-0 pt-1 pb-1" style="border-radius: 10px; color: #31b6f6">
                    <v-icon left color="#31b6f6">mdi-web</v-icon> <b>{{ item.text }}</b>
                  </v-btn>
                </a>
              </v-col>

              <v-col v-else cols="12" class="pl-7 pr-9 pb-1">
                <v-btn block color="white" class="nott elevation-0 pt-1 pb-1" style="border-radius: 10px; color: #31b6f6">
                  <b>{{ item.text }}</b>
                </v-btn>
              </v-col>
            </v-row>
          </div> 
        </div>
      </div>
    </v-col>
    <v-col v-if="!noPadding" cols="2"></v-col>
  </v-row>
</template>
<script>
import moment from 'moment';

export default {
  name: 'TemplateContent',

  props: [
    'iconc',
    'noPadding',
    'previewItem',
    'parseVariables'
  ],

  computed: {
    buttonComponent() {
      return this.previewItem ? this.previewItem.components.find(x => x.type == 'BUTTONS') : false;
    },

    icolor() {
      return this.iconc || 'white';
    }
  },

  methods: {
    parsed(text, header = false) {
      const ivariables = header ? this.previewItem.hvariables : this.previewItem.bvariables; 
      if (!ivariables || !ivariables.length) return text;

      const regExp = /{{([^}]+)}}/g;
      const mvariables = [];

      let varMatch;
      while ((varMatch = regExp.exec(text))) {
        mvariables.push(varMatch[0]);
      }

      mvariables.forEach((x, i) => {
        text = text.replace(x, ivariables[i]);
      });

      return text;
    },

    getFormatted(text) {
      // bold check
      const bold = /\*(.*?)\*/gm;
      text = text.replace(bold, '<b>$1</b>');            

      const italic = /_(.*?)_/gm;
      text = text.replace(italic, '<i>$1</i>'); 

      // strike check
      const strike = /~(.*?)~/gm;
      text = text.replace(strike, '<s>$1</s>'); 

      return text;
    },

    formatTime() {
      return moment().format('h:mm a');
    }
  },
}

</script>