import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";

import config from "../../config";

import Misc from "../../services/misc";
import User from "../../services/user";
import Payment from "../../services/payment";
import Account from "../../services/account";

export default {
  name: "Payments",

  created() {
    this.fetchPayments();
    this.fetchAccounts();

    if (this.haveAccess) {
      this.headers.push({
        text: "Actions",
        align: "right",
        value: "actions",
        sortable: false,
      });
      this.fetchEnums();
      this.fetchUsers();
    }
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    fetchPayments() {
      this.loading = true;

      let Request;
      if (this.showAllPayments) Request = Payment.getAllPayments(false);
      else Request = Payment.getPayments(this.user._id, false);

      return Request.then((x) => {
        const data = x.data || [];
        this.payments = data;
        this.allPayments = JSON.parse(JSON.stringify(data));
        this.loading = false;
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },

    fetchAccounts() {
      this.aloading = true;

      let Request;
      if (this.amISuperadmin) Request = Account.getAllAccounts();
      else Request = Account.getAccounts(this.user._id);

      return Request.then((x) => {
        const data = x.data || [];
        this.accounts = data;
        this.aloading = false;
      }).catch((e) => {
        console.log(e);
        this.aloading = false;
      });
    },

    fetchUsers() {
      this.uloading = true;
      return User.getAllUsers()
        .then((x) => {
          const data = x.data || [];
          this.users = data;
          this.uloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.uloading = false;
        });
    },

    fetchEnums() {
      return Payment.getEnums()
        .then((x) => (this.enums = x.data || {}))
        .catch(() => {});
    },

    formatDate(date, format = "ll") {
      return moment(date).format(format);
    },

    editItem(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      setImmediate(() => (this.dialog = true), 100);
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Payment.deletePayment(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchPayments();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    getSymbolFromCurrency(code) {
      return getSymbolFromCurrency(code);
    },

    userText(item) {
      return item.name + " (" + item.mobile + ")";
    },

    accountText(item) {
      return item.company;
    },

    save() {
      if (!this.$refs.form.validate()) return;
      Misc.snackbarStart();

      let Request;
      this.processing = true;

      const payment = this.editedItem;
      if (payment._id)
        Request = Payment.editPayment(payment._id, payment, false);
      else Request = Payment.addPayment(payment, false);

      return Request.then(() => {
        this.dialog = false;
        this.processing = false;
        Misc.snackbarSuccess();
        this.fetchPayments();
      }).catch(() => {
        this.processing = false;
        Misc.snackbarError();
      });
    },

    exportPayments() {
      let endpoint = `${
        process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
      }/payment/export/${this.user._id}`;
      endpoint =
        endpoint +
        "?x-request-user-agent=application/admin-app&token=" +
        this.$store.state.token;
      window.open(endpoint, "_blank");
    },
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    haveAccess() {
      return this.amISuperadmin || this.amIEmployee;
    },

    amISuperadmin() {
      return Misc.amISuperadmin();
    },

    amIEmployee() {
      return Misc.amIWAEasyAPIEmployee();
    },

    indexed() {
      return this.payments.map((x, index) => {
        x.index = index + 1 + ".";
        return x;
      });
    },

    formTitle() {
      return this.editedIndex === -1 ? "Create new payment" : "Edit payment";
    },
  },

  watch: {
    asearch(n) {
      if (n) {
        const payments = this.allPayments.filter((x) => x.account._id == n);
        this.payments = JSON.parse(JSON.stringify(payments));
      } else this.payments = this.allPayments;
    },

    showAllPayments() {
      this.fetchPayments();
    },
  },

  data() {
    return {
      loading: true,
      aloading: false,
      uloading: false,

      payments: [],
      accounts: [],
      users: [],
      enums: {},

      search: "",
      asearch: "",

      processing: false,
      showAllPayments: false,

      dialog: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
      },

      confirmDialog: false,
      beingDeleted: {},

      headers: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Account", value: "account.company" },
        { text: "Type", value: "type" },
        { text: "Amount", value: "amount" },
        //{ text: 'Method', value: 'paymentMethod' },
        { text: "Status", value: "status" },
        { text: "Created", value: "createdAt" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "Required"],
      },
    };
  },
};
