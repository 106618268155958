import Misc from "../../services/misc";
import Account from "../../services/account";
import config from "../../config";

export default {
  name: "NewAccount",

  created() {
    this.step = 1;
    this.account.name = this.user.name;

    this.fetchCountries();
    this.fetchPricing();

    const params = this.$route.params;
    if (params && params._id) {
      this.account._id = params._id;
      this.getAccount(params._id);
    }
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) {
        this.countries = cs;
        this.processDefaultCountry();
        return;
      }

      this.cfetching = true;
      return Misc.getCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;

          this.processDefaultCountry();
          this.cfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.cfetching = false;
        });
    },

    fetchPricing() {
      this.pfetching = true;
      return Misc.getPricing(true)
        .then((x) => {
          this.pricing = x.data;
          this.pfetching = false;
          this.processPlanAmount();
        })
        .catch((e) => {
          console.log(e);
          this.pfetching = false;
        });
    },

    getAccount(_id) {
      this.afetching = true;
      return Account.getAccount(_id, false)
        .then((res) => {
          const account = res.data;
          this.account = account;
          this.afetching = false;
          this.processDefaultCountry();
          this.processPlanAmount();

          //direct proceed to payment
          if (account.isOTPVerified) {
            this.step = 2;
            this.processAccountPayment();
          }
        })
        .catch(() => {
          this.afetching = false;
          this.navigate("new-account");
        });
    },

    processPlanAmount() {
      if (
        !this.account._id ||
        !this.pricing ||
        (this.pricing.plans && !this.pricing.plans.length)
      )
        return;
      if (this.hasPlanProcessed) return;

      this.pricing.plans.forEach((x) => {
        if (this.account.plan == x._id) {
          x[this.account.currencyCode][this.account.billingCycle] =
            this.account.amount;
          this.hasPlanProcessed = true;
        }
      });
    },

    processDefaultCountry() {
      if (this.countries && this.countries.length) {
        const defaultCountry = this.countries.find((x) => {
          if (x.callingCode === this.account.callingCode) return x;
          if (!this.account.callingCode && x.isDefault) return x;
        });
        if (defaultCountry) this.selectedCountry = defaultCountry;
      }
    },

    text(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    startTimer() {
      this.resendOTPButton = false;
      this.seconds = 59;
      this.myTimer = setInterval(() => {
        this.seconds--;
        if (this.seconds === 0) {
          this.resendOTPButton = true;
          if (this.myTimer) window.clearInterval(this.myTimer);
        }
      }, 1000);
    },

    submit() {
      this.otpSent ? this.verifyOTP() : this.sendOTP();
    },

    sendOTP(forceSend = false) {
      if (!this.$refs.form1.validate() && !forceSend) return;

      if (this.selectedCountry._id) {
        this.account.callingCode = this.selectedCountry.callingCode;
        this.account.country = this.selectedCountry._id;
      }

      this.processing = true;
      this.startTimer();
      return Account.sendOTP(this.account, false)
        .then(() => {
          this.processing = false;
          this.otpSent = true;

          this.$store.state.snackbar.text = "OTP Sent Successfully";
          this.$store.state.snackbar.timeout = 3000;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;
        })
        .catch(() => {
          this.processing = false;
          setTimeout(() => {
            this.showError = false;
          }, 2500);

          this.$store.state.snackbar.text = "Something went wrong, try again";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "error";
          this.$store.state.snackbar.show = true;
        });
    },

    verifyOTP() {
      if (!this.$refs.form1.validate()) return;

      this.processing = true;
      return Account.verifyOTP(this.account, false)
        .then(() => {
          this.processing = false;

          this.$store.state.snackbar.text = "OTP Verified Successfully";
          this.$store.state.snackbar.timeout = 1500;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;

          this.account.isOTPVerified = true;
          this.proceed();
          this.otpSent = false;
        })
        .catch((err) => {
          this.processing = false;
          if (err && err.response && err.response.status === 401)
            this.showError = err.response.data.message;

          this.$store.state.snackbar.text =
            this.showError || "Something went wrong, try again";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "error";
          this.$store.state.snackbar.show = true;
        });
    },

    setDefaultPlan() {
      if (this.account.plan) return;
      const planInfo = this.pricing.plans.find((x) => x._id == "premium");
      const bcycle = "monthly";

      this.account.plan = planInfo._id;
      this.account.billingCycle = bcycle;
      this.account.currencyCode = this.currency;
      this.account.amount = planInfo[this.currency][bcycle];

      this.planInfo = planInfo;
    },

    processPlan() {
      const plan = this.account.plan;
      const bcycle = this.account.billingCycle;
      const planInfo = this.pricing.plans.find((x) => x._id == plan);
      this.account.currencyCode = this.currency;
      this.account.amount = planInfo[this.currency][bcycle];

      this.planInfo = planInfo;
    },

    proceed() {
      if (this.step == 1 && this.$refs.form1.validate()) {
        if (!this.completed.includes(this.step)) this.completed.push(1);
        this.step = 2;
        this.setDefaultPlan();
        return;
      } else if (this.step == 2 && this.$refs.form2.validate()) {
        if (!this.completed.includes(this.step)) this.completed.push(2);
        this.processPlan();
        this.processAccount();
        return;
      } else if (this.step == 3) this.redirectTo360();
      else console.log("invalid");
    },

    redirectTo360() {
      const email = this.account.email;
      const name = this.account.name;
      const company = this.account.company;
      const baseURL = !this.isPort ? config.new360Form : config.port360Form;
      const url = `${baseURL}?email=${email}&fullName=${name}&companyName=${company}&partner_payload=${this.account._id}`;
      this.navigate("home");
      window.open(url, "_blank");
    },

    planSelected(plan) {
      this.account.plan = plan._id;
    },

    getTaxedAmount(amount) {
      return amount;
    },

    getTaxAmount(amount) {
      return parseFloat(((amount * config.gst) / 100).toFixed(2));
    },

    previous() {
      this.step--;
      this.completed.pop();
    },

    processAccount() {
      this.aprocessing = true;
      Misc.snackbarStart();

      if (!this.account.country) {
        this.account.country = this.selectedCountry._id;
        this.account.callingCode = this.selectedCountry.callingCode;
      }

      if (this.isPort) this.account.isPort = true;

      let Request;
      const account = Object.assign({}, this.account);
      if (account._id)
        Request = Account.editAccount(account._id, account, false);
      else Request = Account.createAccount(account, false);

      return Request.then((res) => {
        const data = res.data;
        if (data) {
          this.$store.state.snackbar.show = false;
          this.account._id = data._id;
          this.processAccountPayment();
        } else {
          this.$store.state.snackbar.text =
            "Something went wrong; please try later";
        }

        this.aprocessing = false;
      }).catch(() => {
        //console.log(e);
        Misc.snackbarError();
        this.aprocessing = false;
        this.$store.state.snackbar.text = "Mobile number already exists";
      });
    },

    processAccountPayment() {
      const nthis = this;
      let amount = this.account.amount;
      const company = this.account.company;
      const currencyCode = this.account.currencyCode;
      const billingCycle = this.$options.filters.firstLetterUppercase(
        this.account.billingCycle
      );
      const description = `${company} - ${billingCycle} Subscription`;

      if (this.bcycle == "monthly") amount = amount * 2;

      const options = Misc.getRazOptions(
        currencyCode,
        amount,
        description,
        this.user
      );
      options.handler = function (response) {
        nthis.processPayment(response.razorpay_payment_id);
      };
      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      if (!paymentId) {
        Misc.snackbarError();
        this.$store.state.snackbar.text = "Payment Unsuccessful";
        return;
      }

      Misc.snackbarStart();
      this.processing = true;

      let amount = this.account.amount;
      if (this.bcycle == "monthly") amount = amount * 2;

      const object = {
        paymentId: paymentId,
        amount: amount,
        currencyCode: this.account.currencyCode,
      };

      return Account.processPayment(this.account._id, object, false)
        .then(() => {
          this.$store.state.snackbar.show = false;
          this.processing = false;
          this.processingSuccessful();
        })
        .catch((e) => {
          console.log(e);
          Misc.snackbarError();
          this.$store.state.snackbar.text =
            "Something went wrong; please contact our team";
        });
    },

    processingSuccessful() {
      this.step = 3;
      this.completed.push(3);
    },
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    numberRules() {
      if (this.selectedCountry) {
        const length = this.selectedCountry.minNumberLength || 10;
        return [
          (v) =>
            (!!v &&
              !isNaN(this.account.mobile) &&
              this.account.mobile.length >= length) ||
            "Mobile number is required",
        ];
      }
      return this.validationRules.mobileRules;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    getTimer() {
      return "00:" + (this.seconds < 10 ? "0" + this.seconds : this.seconds);
    },

    currency() {
      return this.selectedCountry.currencyCode == "INR" ? "INR" : "USD";
    },

    pricingPlans() {
      return this.pricing.plans || [];
    },

    bcycle() {
      return this.account.billingCycle;
    },

    isPort() {
      return this.$route.name == "port-account" || this.account.isPort;
    },
  },

  data() {
    const emailregex =
      /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      otpSent: false,
      processing: false,
      aprocessing: false,

      resendOTPButton: false,
      myTimer: false,
      seconds: 59,

      btypes: [
        {
          _id: "monthly",
          name: "Monthly",
        },
        {
          _id: "yearly",
          name: "Yearly",
        },
      ],

      step: 1,
      completed: [],
      planInfo: {},
      pfetching: false,
      pricing: false,
      hasPlanProcessed: false,

      account: {
        name: "",
        email: "",

        callingCode: "",
        mobile: "",

        company: "",
        taxCode: "",
        brand: "",
        address: "",

        plan: "",
        amount: 0,
        currencyCode: "",
        billingCycle: "",
      },

      cfetching: false,
      countries: [],
      selectedCountry: {},

      paymentSuccessModal: false,
      checkmarkAnimationJson: config.checkmarkAnimationJson,

      ccdiscount: 0,
      couponCode: "",
      ccapplied: false,
      coupons: [{ code: "GROWCIFY22", discount: 10, type: "percent" }],

      validationRules: {
        mobileRules: [
          (v) => !!v || "Mobile number is required",
          (v) =>
            /^[0]?[56789]\d{9}$/.test(v) ||
            "Please enter a valid mobile number",
        ],
        otpRules: [(v) => (v && this.otpSent) || "OTP is required"],
        basicRule: [(v) => !!v || "Name is required"],
        brandRule: [(v) => !!v || "Brand name is required"],
        companyRule: [(v) => !!v || "Company name is required"],
        countryRule: [(v) => !!v || "Country is required"],
        emailRule: [
          (v) => (v && emailregex.test(v)) || "Must be a valid email",
        ],
        billingRule: [(v) => !!v || "Billing cycle is required"],
      },
    };
  },
};
