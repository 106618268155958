import Vue from 'vue';
import config from '../config';
import store from '../store';

export default {
  getCountries(noProgress = true) {
    return Vue.axios.get(`country/list/active`, { noProgress: noProgress });
  },

  getPricing(noProgress = true) {
    return Vue.axios.get(`pricing/plans`, { noProgress: noProgress });
  },

  amISuperadmin() {
    return store.state.userInfo.roles.includes('superadmin');
  },

  amIWAEasyAPIEmployee() {
    return store.state.userInfo.roles.includes('waeasyapi-employee');
  },

  snackbarStart() {
    store.state.snackbar.show = false;
    store.state.snackbar.text = store.state.snackbar.submittingText;
    store.state.snackbar.color = 'primary';
    store.state.snackbar.timeout = 0;
    store.state.snackbar.show = true;
  },

  snackbarSuccess() {
    store.state.snackbar.show = false;
    store.state.snackbar.text = store.state.snackbar.submittedText;
    store.state.snackbar.timeout = 2500;
    store.state.snackbar.color = 'success';
    store.state.snackbar.show = true;
    setTimeout(() => store.state.snackbar.show = false, 2500);
  },

  snackbarError() {
    store.state.snackbar.show = false;
    store.state.snackbar.text = store.state.snackbar.errorText;
    store.state.snackbar.timeout = 2500;
    store.state.snackbar.color = 'error';
    store.state.snackbar.show = true;
    setTimeout(() => store.state.snackbar.show = false, 2500);
  },

  getRazOptions(currencyCode = 'INR', amount, description, userInfo = {}) {
    amount = parseFloat((amount*100).toFixed(2));
    return {
      key: config.razorpay.key,
      name: 'WA Easy API',
      description: description,
      image: 'https://waeasyapi.com/images/logo.png',
      amount: amount,
      currency: currencyCode,
      prefill: {
        name: userInfo.name || '',
        email: userInfo.email || '',
        contact: userInfo.callingCode+userInfo.mobile || '',
      },
      theme: {
        "color": "#075E54"
      }
    };
  },

  fileUpload(accountId, fileType, file, format = 'image') {
    const formData = new FormData()
    formData.append('file', file, file.name)
    return Vue.axios.post(`file/${accountId}/${fileType}/${format}`, formData);
  },

  getImageURL(data) {
    if (!data.includes('http') && data && (data.split('.')[1] === 'png')) return `${config.waeasyapiS3}${data}`;
    return data;
  },

  testRequest() {
    return Vue.axios.post(`test/send`, {});
  },

  uploadMedia(accountId, type, file, noProgress = true) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return Vue.axios.post(`media/upload/${type}/${accountId}`, formData, { noProgress: noProgress });
  },
}