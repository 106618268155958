import Misc from '../../services/misc';
import Account from '../../services/account';
import Contact from '../../services/contact';

import ImageWithLoader from '../../components/image-with-loader/image-with-loader.vue';

export default {
  name: 'ProfileTop',

  props: [
    'item',
    'size'
  ],

  components: {
    ImageWithLoader
  },

  computed: {
    image() {
      return this.account.image ? this.account.image : false;
    },

    account() {
      return this.$store.state.activeAccount;
    },

    isAccount() {
      return this.item._id == this.account._id;
    }
  },

  methods: {
    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (file && (file['type'].split('/')[0] === 'image')) {
        this.type = file['type'].split('/')[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      return Misc.getImageURL(data);
    },

    invalidImageError() {
      this.$store.state.snackbar.text = 'Invalid file format';
      this.$store.state.snackbar.color = 'error';
      this.$store.state.snackbar.show = true;
    },

    async fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      Misc.snackbarStart();
      this.uploading = true;
      this.photo = URL.createObjectURL(file);
      return Misc.fileUpload(this.account._id, 'profile-photos', file, this.type)
        .then(x => {
          this.photo = x.data.file;
          this.hover = true;
          this.savePhoto();
        })
        .catch(e => {
          console.log(e);
          this.uploading = false;
          Misc.snackbarError();
        })
    },

    savePhoto() {
      let object = { image: this.photo };
      let Request;
      if (this.isAccount) {
        Request = Account.changeProfilePhoto(this.item._id, object, false);
      } else if (this.item.isBroadcast) {
        Request = Contact.editBroadcast(this.item._id, object, false);
      } else {
        Request = Contact.editContact(this.item._id, object, false);
      }

      return Request
        .then(resp => {
          this.$emit('update:profilePhotoUpdated', { isAccount: this.isAccount, image: this.photo });
          this.uploading = false;
          
          const data = resp.data;
          if (data && !data.ok && data.message) {
            Misc.snackbarError();
            this.$store.state.snackbar.text = data.message;
          } else {
            Misc.snackbarSuccess();
          }
        })
        .catch(() => {
          Misc.snackbarError();
        });
    }
  },

  data() {
    return {
      loading: false,
      processing: false,
      uploading: false,

      photo: '',
      type: ''
    }
  }
}