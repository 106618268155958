import $ from "jquery";
import _ from "underscore";
import moment from "moment";
import * as linkify from "linkifyjs";
import * as libphonenumber from "libphonenumber-js";
import * as emojiset from "../../../emojis.json";
import { v1 as uuidv1 } from "uuid";

import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";

import config from "../../../config";

import Misc from "../../../services/misc";
import Inbox from "../../../services/inbox";
import Contact from "../../../services/contact";
import Account from "../../../services/account";
import Template from "../../../services/template";

import ProfileTop from "../../../components/profile-top/profile-top.vue";
import LabelEditor from "../../../components/label-editor/label-editor.vue";
import ImageWithLoader from "../../../components/image-with-loader/image-with-loader.vue";
import TemplateContent from "../../../components/template-content/template-content.vue";
import MediaLoader from "../../../components/media-loader/media-loader.vue";
import MessageItem from "../../../components/message-item/message-item.vue";
import MessageTicker from "../../../components/message-ticker/message-ticker.vue";
import ContactForm from "../../../components/contact-form/contact-form.vue";
import MessageTimer from "../../../components/message-timer/message-timer.vue";

export default {
  name: "AccountInbox",

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate("home");

    document.documentElement.style.overflow = "hidden";
    this.getInbox();
    this.getContacts();
    this.getBroadcasts();
    this.fetchProfile();
    this.fetchTemplates();
    this.initPusher();
    setTimeout(() => this.getRestInbox(), 250);

    document.addEventListener("visibilitychange", () => {
      this.isWindowActive = !document.hidden;
    });
  },

  destroyed() {
    document.documentElement.style.overflow = "auto";
  },

  directives: {
    viewer: viewer({}),
  },

  components: {
    LabelEditor,
    ProfileTop,
    ImageWithLoader,
    TemplateContent,
    MediaLoader,
    MessageItem,
    MessageTicker,
    ContactForm,
    MessageTimer,
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    initPusher() {
      const channelName = this._id;

      const pusher = this.$store.state.pusher;
      const channel = pusher.subscribe(channelName);

      const events = [
        "messageRead",
        "messageDelivered",
        "newMessage",
        "newChat",
        "apiMessageSent",
      ];

      events.forEach((event) => {
        channel.unbind(event);
        channel.bind(event, (data) => {
          this.pusherHandler(data);
        });
      });
    },

    show() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },

    pusherHandler(data) {
      const ichat = data.chat;
      const message = data.message;
      const eventName = data.eventName;

      if (eventName == "newChat") {
        this.chats.unshift(ichat);

        if (!this.$store.state.mute) {
          this.processNotification(ichat);
        }

        this.processUnreadTitle();
      } else if (eventName == "newMessage") {
        message.loading = false;

        const chat = this.chats.find((x) => x._id == ichat._id);
        chat.unreadCount = ichat.unreadCount;
        chat.expiresAt = ichat.expiresAt;
        chat.messages.push(message);
        this.shiftTop(chat);

        // process auto download media
        const isAchat = chat._id == this.achat._id;
        const isSticker = message.type == "sticker";
        if (isAchat && isSticker) this.processMessageMedia(message);

        if (!this.$store.state.mute) {
          this.processNotification(ichat);
        }

        this.processUnreadTitle();
      } else if (eventName == "apiMessageSent") {
        const chat = this.chats.find((x) => x._id == ichat._id);
        if (!chat) this.chats.unshift(ichat);
        else {
          chat.expiresAt = ichat.expiresAt;
          chat.messages.push(message);
          this.shiftTop(chat);
        }

        this.processUnreadTitle();
      } else if (["messageDelivered", "messageRead"].includes(eventName)) {
        const chat = this.chats.find((x) => x._id == ichat._id);
        if (chat) {
          chat.expiresAt = ichat.expiresAt;
          const cmsg = chat.messages.find((x) => x._id == message._id);
          if (cmsg)
            cmsg.status = eventName == "messageRead" ? "read" : "delivered";
        }
      }

      this.scrollBottom();
    },

    processNotification(chat) {
      const canPlay =
        !chat.mute && (!this.isWindowActive || this.achat._id != chat._id);
      if (canPlay) this.playAudio();
    },

    playAudio() {
      const mp3file = require("@/assets/files/wa-message.mp3");
      new Audio(mp3file).play();
    },

    notify(title, body = {}) {
      this.$notification.show(title, body, {});
    },

    getURL(data) {
      return Misc.getImageURL(data);
    },

    formatDate(date, format = "ll") {
      return moment(date).format(format);
    },

    formatTime(date) {
      return moment(date).format("h:mm a");
    },

    fromNow(date) {
      return moment(date).fromNow();
    },

    formatMessageTime(time) {
      if (moment().isSame(moment(time), "day"))
        return moment(time).format("h:mm a");
      if (!moment().diff(moment(time), "day")) return "yesterday";
      return moment(time).format("DD/MM/YY");
    },

    leftback() {
      this.searchIcon = "mdi-magnify";
      this.$refs.leftSearch.$refs.input.blur();
    },

    getInbox() {
      this.loading = true;
      return Inbox.getInbox(this._id, false)
        .then((x) => {
          this.allChats = x.data;
          this.chats = x.data.all || [];
          this.loading = false;

          this.processUnreadTitle();
          if (this.$store.state.rcInfo) this.redirectionCheck();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    getRestInbox() {
      this.riloading = true;
      return Inbox.getRestInbox(this._id, false)
        .then((x) => {
          const data = x.data;
          if (data) {
            if (data.all && data.all.length) {
              data.all.forEach((x) => this.allChats.all.push(x));
            }

            if (data.pinned && data.pinned.length) {
              data.pinned.forEach((x) => this.allChats.pinned.push(x));
            }

            if (data.archived && data.archived.length) {
              data.archived.forEach((x) => this.allChats.archived.push(x));
            }
          }

          this.riloading = false;
          this.processUnreadTitle();
          if (this.$store.state.rcInfo) this.redirectionCheck();
        })
        .catch((e) => {
          console.log(e);
          this.riloading = false;
        });
    },

    processUnreadTitle() {
      const unreadChats = this.chats.filter((x) => x.unreadCount);
      document.title = `WA Easy API`;
      if (unreadChats.length) {
        document.title = "(" + unreadChats.length + ") " + document.title;
      }
    },

    getContacts(silent = false) {
      if (!silent) this.cloading = true;
      return Contact.getAllContacts(this._id, false)
        .then((x) => {
          const data = x.data;
          this.contacts = data;
          this.allContacts = data;
          this.cloading = false;

          const rc = this.$store.state.rcInfo;
          if (rc && rc.type == "contact") this.redirectionCheck();
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    getBroadcasts() {
      this.bloading = true;
      return Contact.getAllBroadcasts(this._id, false)
        .then((x) => {
          const data = x.data;
          this.broadcasts = data;
          this.allBroadcasts = data;
          this.bloading = false;

          const rc = this.$store.state.rcInfo;
          if (rc && rc.type == "broadcast") this.redirectionCheck();
        })
        .catch((e) => {
          console.log(e);
          this.bloading = false;
        });
    },

    getContactBroadcasts(contactId) {
      this.bloading = true;
      return Contact.getContactBroadcasts(this._id, contactId, false)
        .then((x) => {
          const data = x.data;
          this.achat.broadcasts = data;
          this.bloading = false;

          this.alreadyFetchedBroadcasts[contactId] = JSON.parse(
            JSON.stringify(this.achat.broadcasts)
          );
        })
        .catch((e) => {
          console.log(e);
          this.bloading = false;
        });
    },

    getContactLabels(contactId) {
      this.lloading = true;
      return Contact.getContactLabels(this._id, contactId, false)
        .then((x) => {
          const data = x.data;
          this.achat.labels = data;
          this.lloading = false;
          this.alreadyFetchedLabels[contactId] = data;
        })
        .catch((e) => {
          console.log(e);
          this.lloading = false;
        });
    },

    getBroadcastLabels(broadcastId) {
      this.lloading = true;
      return Contact.getBroadcastLabels(this._id, broadcastId, false)
        .then((x) => {
          const data = x.data;
          this.achat.labels = data;
          this.lloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.lloading = false;
        });
    },

    getBroadcastText(item, length = 3) {
      const ps = JSON.parse(JSON.stringify(item.recipients));
      const total = ps ? ps.length : 0;
      let text = total + " recipients";
      if (total) {
        let sliced = ps.slice(0, length);
        if (length)
          sliced = sliced.map((x) => {
            if (typeof x == "string") return x;
            if (
              x.callingCode == this.user.callingCode &&
              x.mobile == this.user.mobile
            )
              x.name = "You";
            x.name = this.$options.filters.firstName(x.name);
            return x.name;
          });
        text = sliced.join(", ");
        if (total > length) text += ", and " + (total - 3) + " more";
      }

      return text;
    },

    fetchProfile() {
      this.profile.loading = true;
      return Account.getProfile(this._id, false)
        .then((x) => {
          const profile = x.data;
          if (profile.photo) this.profile.photo = profile.photo;
          if (profile.about) this.profile.about = profile.about;
          if (profile.description)
            this.profile.description = profile.description;
          if (profile.vertical) this.profile.vertical = profile.vertical;
          if (profile.address) this.profile.address = profile.address;
          if (profile.email) this.profile.email = profile.email;
          if (profile.website) this.profile.website = profile.website;

          this.profile.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.profile.loading = false;
        });
    },

    fetchTemplates() {
      this.tloading = true;
      return Template.getTemplates(this._id, false)
        .then((x) => {
          const data = x.data;
          this.templates = data;
          this.allTemplates = data;
          this.tloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.tloading = false;
        });
    },

    updateBusinesProfile(type = "about") {
      if (!this.$refs.profileForm.validate()) return;
      Misc.snackbarStart();

      let object;
      let Request;
      if (type == "about") {
        this.profile.paprocessing = true;
        object = { about: this.account.about };
        Request = Account.changeProfileAbout(this._id, object, false);
      } else {
        this.profile.pbprocessing = true;
        object = {
          vertical: this.account.vertical,
          description: this.account.description,
          email: this.account.email,
          website: this.account.website,
          address: this.account.address,
        };
        Request = Account.changeBusinesProfile(this._id, object, false);
      }

      return Request.then((resp) => {
        if (type == "about") {
          this.profile.editAbout = false;
          this.profile.paprocessing = false;
        } else {
          this.profile.editBusiness = false;
          this.profile.pbprocessing = false;
        }

        const data = resp.data;
        if (data && !data.ok && data.message) {
          Misc.snackbarError();
          this.$store.state.snackbar.text = data.message;
        } else {
          Misc.snackbarSuccess();
        }
      }).catch((e) => {
        console.log(e);
        Misc.snackbarError();
        this.profile.paprocessing = false;
        this.profile.pbprocessing = false;
      });
    },

    redirectionCheck() {
      if (this.loading || this.cloading || this.bloading) return;

      const rchat = this.$store.state.rcInfo;
      if (rchat.type == "contact") {
        // first search in chats
        const rcInfo = rchat.info;
        const chat = this.chats.find((x) => x.contact == rcInfo._id);
        if (chat) this.selectChat(chat);
        else if (!this.riloading) {
          //else look for contacts and start
          const contact = this.contacts.find((x) => x._id == rcInfo._id);
          if (contact) this.composeNewChat(contact);
        }
      } else if (rchat.type == "broadcast") {
        // first search in chats
        const rcInfo = rchat.info;
        const chat = this.chats.find((x) => x.broadcast == rcInfo._id);
        if (chat) this.selectChat(chat);
        else if (!this.riloading) {
          //else look for contacts and start
          const broadcast = this.broadcasts.find((x) => x._id == rcInfo._id);
          if (broadcast) this.composeNewChat(broadcast, "broadcast");
        }
      }

      // reset to no one
      if (!this.riloading) this.$store.state.rcInfo = false;
    },

    composeSubNewChat(chat, type = "contact") {
      let item = chat;
      if (type == "broadcast")
        item = this.allBroadcasts.find((x) => x._id == chat._id);
      else item = this.allContacts.find((x) => x._id == chat._id);

      this.composeNewChat(item, type);
    },

    composeNewChat(chat, type = "contact") {
      //if it's new
      const existingChat = this.chats.find(
        (x) => x.contact == chat._id || x.broadcast == chat._id
      );
      if (existingChat) this.selectChat(existingChat);
      else {
        chat.isNew = true;
        chat.messages = [];
        this.selectChat(chat);
      }

      this.closeNewChat();
    },

    selectChat(chat) {
      //if existing or composed already
      this.achat = chat;
      this.showInfo = false;

      this.closeRightSearch();
      if (chat.unreadCount) this.chatRead(chat._id);

      setTimeout(() => {
        this.scrollBottom();
        if (this.$refs.typeMessage) this.$refs.typeMessage.$refs.input.focus();
      }, 0);
    },

    startChat(contact, type = "contact") {
      this.composeNewChat(contact, type);
      this.closeNewChat();
    },

    scrollBottom(animated = false) {
      const element = $(".chat-area1");

      if (!element.scrollTop && !element.animate) return;
      if (!element[0]) return;

      setTimeout(() => {
        if (!animated)
          $(".chat-area1").scrollTop($(".chat-area1")[0].scrollHeight);
        else
          $(".chat-area1").animate(
            { scrollTop: $(".chat-area1").prop("scrollHeight") },
            1000
          );
      }, 0);
    },

    tweet() {
      const text =
        "Just wow! Love @waeasyapi! An efficient way to manage WhatsApp Business. Kudos to the team! https://waeasyapi.com!&hashtags=whatsapp,business,apis,waeasyapi";
      const url = "https://twitter.com/intent/tweet?text=" + text;
      window.open(url, "_blank");
    },

    openInfo() {
      this.showInfo = true;
      if (!this.achat.isBroadcast) this.fetchInfo();
    },

    fetchInfo() {
      if (!this.achat._id) return; //for faker

      if (this.alreadyFetchedFor.some((x) => x == this.achat._id)) {
        if (!this.achat.labels)
          this.achat.labels = this.alreadyFetchedLabels[this.achat._id] || [];
        if (!this.achat.broadcasts)
          this.achat.broadcasts =
            this.alreadyFetchedBroadcasts[this.achat._id] || [];
        return;
      }

      this.getContactBroadcasts(this.achat._id);
      this.getContactLabels(this.achat._id);

      this.alreadyFetchedFor.push(this.achat._id);
    },

    closeInfo() {
      this.showInfo = false;
    },

    startNewChat() {
      this.newChat.show = true;
    },

    closeNewChat() {
      this.searchIcon = "mdi-magnify";
      if (this.$refs.leftChatSearch)
        this.$refs.leftChatSearch.$refs.input.blur();
      this.newChat.keyword = "";
      this.newChat.show = false;
    },

    anyColor() {
      const colors = config.colors;
      return colors[Math.floor(Math.random() * colors.length)];
    },

    modifyLabels() {
      this.beingModified = this.achat;
      this.$store.state.labelEditorModal = true;
    },

    openRightSearch() {
      this.showInfo = false;
      this.rightSearch.show = true;
      this.rightSearch.keyword = "";
      this.rightSearch.messages = [];
    },

    closeRightSearch() {
      this.rightSearch.show = false;
    },

    getTemplateContent(item) {
      const components = item.components;
      const body = components.find((x) => x.type == "BODY");
      return body.text;
    },

    amenuSelected(item) {
      if (item._id == "close") {
        this.closeInfo();
        this.closeRightSearch();
        this.achat = false;
      } else if (item._id == "info") {
        this.closeRightSearch();
        this.openInfo();
      } else {
        console.log(item);
      }
    },

    pmenuSelected(item) {
      if (item._id == "new-chat") this.startNewChat();
      else if (item._id == "logout") this.$store.commit("logout");
      else if (item._id == "mute")
        this.$store.state.mute = !this.$store.state.mute;
      else if (item._id == "archived") {
        if (this.archivedChats) this.chats = this.allChats.all;
        else this.chats = this.allChats.archived;
        this.archivedChats = !this.archivedChats;

        this.closeInfo();
        this.closeRightSearch();
        this.achat = false;
      }
    },

    cmenuSelected(item, chat) {
      if (item._id == "mute") this.muteUnmuteChat(chat);
      else if (item._id == "pin") this.pinUnpinChat(chat);
      else if (item._id == "archive") this.archiveUnarchiveChat(chat);
    },

    urlify(text) {
      const response = linkify.find(text);
      if (!response || !response.length) return text;

      response.forEach((x) => {
        if (["url", "email"].includes(x.type)) {
          text = text.replace(
            x.value,
            `<a href="${x.href}" target="_blank" class="text-link">${x.value}</a>`
          );
        }
      });

      return text;
    },

    getFormatted(text, highlightSearch = false) {
      // bold check
      const bold = /\*(.*?)\*/gm;
      text = text.replace(bold, "<b>$1</b>");

      const italic = /_(.*?)_/gm;
      text = text.replace(italic, "<i>$1</i>");

      // strike check
      const strike = /~(.*?)~/gm;
      text = text.replace(strike, "<s>$1</s>");

      // for highlightSearch
      if (text && highlightSearch)
        text = text.replaceAll(
          this.rightSearch.keyword,
          '<b class="text-read">' + this.rightSearch.keyword + "</b>"
        );

      // link check
      if (text) text = this.urlify(text);

      return text;
    },

    // messaging methods
    initSendTemplate(item) {
      const body = item.components.find((x) => x.type == "BODY");
      let message = body.text;
      const regExp = /{{([^}]+)}}/g;
      const bvariables = [];

      let varMatch;
      while ((varMatch = regExp.exec(message))) {
        const vobject = {
          value: "",
          text: varMatch[0],
        };
        bvariables.push(vobject);
      }

      this.templateItem = JSON.parse(JSON.stringify(item));
      this.templateItem.bvariables = bvariables;

      if (!bvariables.length) {
        const body = this.templateItem.components.find((x) => x.type == "BODY");
        this.templateItem.message = body.text;
        this.sendTemplate();
      } else this.composeTemplate = true;
    },

    variableChanged() {
      const body = this.templateItem.components.find((x) => x.type == "BODY");
      if (!body.frized) body.frized = body.text;
      let text = body.frized;
      this.templateItem.bvariables.forEach((x) => {
        if (x.value) text = text.replace(x.text, x.value);
      });
      body.text = text;
      this.templateItem.message = text;
    },

    initSending(type = "text") {
      this.action3 = false; // close emoji
      this.message.sending = true;
      this.message.sentBy = "business";
      this.message.type = type;

      const msg = JSON.parse(JSON.stringify(this.message));
      this.achat.messages.push(msg);

      this.message.text = ""; // reset
      if (!this.achat.isNew) this.shiftTop(this.achat);
      setTimeout(() => this.scrollBottom(), 0);
    },

    completeSending(data, uuid = "") {
      const message = data.message;
      if (!message) return;
      const messages = JSON.parse(JSON.stringify(this.achat.messages));

      // reset messages
      const nmessages = [];
      messages.forEach((item) => {
        if (item.uuid == uuid) item = message;
        nmessages.push(item);
      });

      if (["image", "video"].includes(message.type)) nmessages.push(message);
      this.achat.messages = nmessages;

      this.scrollBottom();
      if (this.achat.unreadCount) this.chatRead(this.achat._id);
      if (nmessages.every((x) => !x.uuid)) this.message.sending = false;
    },

    resetComposeTemplate() {
      this.action2 = false;
      this.templateItem = {};
      this.composeTemplate = false;
    },

    sendTemplate() {
      if (this.templateItem.bvariables.length) {
        if (!this.$refs.tform.validate()) return;
      }

      const uuid = uuidv1();
      this.message.uuid = uuid;

      const waID = this.achat.contactWAID || this.achat.waID;
      const template = this.templateItem;

      this.message.text = template.message;
      const object = {
        number: waID,
        params: {
          name: template.name,
          language: { policy: "deterministic", code: template.language },
          components: template.components,
        },
        message: template.message,
        bvariables: template.bvariables,
      };
      this.initSending("template");

      let Request;
      if (this.achat.isContact)
        Request = Inbox.sendContactTemplate(this._id, this.achat._id, object);
      else if (this.achat.isBroadcast)
        Request = Inbox.sendBroadcastTemplate(this._id, this.achat._id, object);
      else Request = Inbox.sendChatTemplate(this._id, this.achat._id, object);

      return Request.then((resp) => {
        const data = resp.data;
        if (this.achat.isNew) {
          this.fetchChat(data.chatId);
        } else {
          this.resetComposeTemplate();
          this.completeSending(data, uuid);
          this.shiftTop(this.achat);
        }
        this.achat.expiresAt = data.expiresAt;
      }).catch((e) => {
        if (e.response && e.response.data) {
          const errors = e.response.data.errors;
          if (errors) {
            const error = errors[0];
            this.throwError(error.title || "Something went wrong, try later");
          }
        }
        this.message.sending = false;
        this.message.failed = true;
      });
    },

    preventEnter() {},
    handleMessageKeydown(e) {
      if (e.shiftKey && e.key == "Enter") {
        if (!this.message.text) return e.preventDefault();
        return;
      } else if (e.key == "Enter") {
        if (!this.message.text) return e.preventDefault();
        this.sendTextMessage();
        setTimeout(() => (this.message.text = undefined), 1);
      }
    },

    sendTextMessage() {
      if (!this.message.text) return;

      const uuid = uuidv1();
      this.message.uuid = uuid;

      this.outstandingRequests++;
      const waID = this.achat.contactWAID || this.achat.waID;
      const object = {
        number: waID,
        message: this.message.text,
      };
      this.initSending();

      return Inbox.sendTextMessage(this._id, this.achat._id, object)
        .then((resp) => {
          this.outstandingRequests--;
          this.completeSending(resp.data, uuid);
        })
        .catch(() => {
          this.outstandingRequests--;
          this.message.sending = false;
          this.message.failed = true;
        });
    },

    // media uploader and processer
    getMediaRef(type) {
      if (type == "image") return this.$refs.imgPicker;
      if (type == "video") return this.$refs.videoPicker;
      if (type == "audio") return this.$refs.audioPicker;
      if (type == "document") return this.$refs.documentPicker;
    },

    initMediaUpload(type = "image") {
      this.getMediaRef(type).click();
    },

    isValidMedia(file, type) {
      if (file && file["type"].split("/")[0] == type) return true;
      if (type == "document") return true;
      return false;
    },

    async mediaSelected(type) {
      const file = this.getMediaRef(type).files[0];
      this.media = file;
      const isValid = this.isValidMedia(file, type);
      if (!isValid)
        return this.throwError("Please select a valid " + type + " file.");

      this.initMediaMessage(type);
    },

    initMediaMessage(type) {
      if (["document", "audio", "voice"].includes(type)) {
        if (type == "document") {
          this.message.document = {
            mime_type: this.media.type,
            filename: this.media.name,
          };
        } else if (type == "audio") this.message.audio = {};
        else if (type == "voice") this.message.voice = {};

        const uuid = uuidv1();
        this.message.uuid = uuid;

        this.uploadMedia(type, this.media, uuid);
        this.initSending(type);
      } else if (["image", "video"].includes(type)) {
        this.mediaObject.type = type;
        this.mediaObject.url = URL.createObjectURL(this.media);

        this.message.type = type;
        this.message[type] = {};

        if (this.mediaObject.caption) {
          this.message[type].caption = this.mediaObject.caption;
        }

        this.mediaObject.preview = true;
      }
    },

    resetMedia() {
      this.media = false;
      this.mediaObject = { type: "", url: "", preview: false };
      this.message.uploading = false;
      setTimeout(() => this.scrollBottom(), 100);
    },

    resetLastMessage() {
      this.achat.messages.pop();
      this.message = JSON.parse(JSON.stringify(this.dmessage));
    },

    initUpload() {
      const file = this.media;
      const type = this.mediaObject.type;
      this.avoidPop = true;
      this.uploadMedia(type, file);
    },

    uploadMedia(type, file, uuid = "") {
      this.message.uploading = true;
      return Inbox.uploadMedia(this._id, this.achat._id, type, file)
        .then((x) => {
          if (x.data.ok) this.processMediaMessage(type, x.data.data, uuid);
          else {
            this.throwError("Uploading failed, please try again");
            this.resetLastMessage();
          }
        })
        .catch((e) => {
          console.log(e);
          this.throwError("Uploading failed, please try again");
          this.message.uploading = false;
        });
    },

    processMediaMessage(type, data, uuid = "") {
      if (["document", "audio", "voice"].includes(type)) {
        const params = { id: data.id };
        if (type == "document") {
          params.filename = this.media.name;
          params.mime_type = this.media.type;
        }

        this.sendMediaMessage(type, params, uuid);
      } else if (["image", "video"].includes(type)) {
        const params = { id: data.id };
        if (this.mediaObject.caption) {
          params.caption = this.mediaObject.caption;
        }

        this.sendMediaMessage(type, params, uuid);
      }
    },

    sendMediaMessage(type, params, uuid = "") {
      const waID = this.achat.contactWAID || this.achat.waID;
      const object = {
        number: waID,
        params: params,
      };

      return Inbox.sendMediaMessage(this._id, type, this.achat._id, object)
        .then((resp) => {
          this.completeSending(resp.data, uuid);
          this.resetMedia();
        })
        .catch(() => {
          this.message.sending = false;
          this.message.failed = true;
        });
    },

    fetchChat(chatId) {
      return Inbox.getChat(chatId)
        .then((x) => {
          this.achat = x.data;
          this.chats.unshift(this.achat);
          this.message.sending = false;
          this.resetComposeTemplate();
        })
        .catch((e) => {
          console.log(e);
          this.message.sending = false;
        });
    },

    chatRead(chatId) {
      return Inbox.chatRead(chatId)
        .then(() => {
          this.achat.unreadCount = 0;
          this.processUnreadTitle();
        })
        .catch((e) => console.log(e));
    },

    muteUnmuteChat(chat) {
      const action = chat.mute ? "unmute" : "mute";
      chat.mute = action == "unmute" ? false : true;
      return Inbox.performAction(chat._id, action);
    },

    pinUnpinChat(chat) {
      const action = chat.pinned ? "unpin" : "pin";
      chat.pinned = action == "unpin" ? false : true;
      return Inbox.performAction(chat._id, action);
    },

    archiveUnarchiveChat(chat) {
      const action = chat.archived ? "unarchive" : "archive";
      chat.archived = action == "unarchive" ? false : true;
      return Inbox.performAction(chat._id, action);
    },

    hexToBase64(str) {
      return btoa(
        String.fromCharCode.apply(
          null,
          str
            .replace(/\r|\n/g, "")
            .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
            .replace(/ +$/, "")
            .split(" ")
        )
      );
    },

    async processMessageMedia(message) {
      const item = message;
      if (item.file) return;
      let mediaId;

      if (item.type == "image") mediaId = item.image.id;
      else if (item.type == "audio") mediaId = item.audio.id;
      else if (item.type == "voice") mediaId = item.voice.id;
      else if (item.type == "document") mediaId = item.document.id;
      else if (item.type == "video") mediaId = item.video.id;
      else if (item.type == "sticker") mediaId = item.sticker.id;

      item.loading = true;
      const mresponse = await this.getMedia(mediaId);
      const rdata = mresponse.data;
      if (rdata.ok) item.file = rdata.data;
      item.loading = false;

      this.scrollBottom(true);
      return message;
    },

    async processMedia() {
      const chat = this.achat;
      const messages = chat.messages;
      if (!messages || !messages.length) return;

      const mediaMessages = messages.filter((x) =>
        ["image", "audio", "video", "document", "video", "sticker"].includes(
          x.type
        )
      );
      mediaMessages.forEach((x) => (x.loading = false));

      const promises = mediaMessages.map(async (item) => {
        await this.processMessageMedia(item);
        return item;
      });

      await Promise.all(promises);
    },

    getMedia(mediaId) {
      return Inbox.getMedia(this._id, mediaId);
    },

    lastMessage(chat) {
      let message = {
        text: "",
        read: false,
        status: "sent",
        createdAt: "",
        sending: false,
      };
      if (chat.messages && chat.messages.length) {
        const length = chat.messages.length;
        message = chat.messages[length - 1];
      }

      return message;
    },

    shiftTop(chat) {
      const index = this.chats.findIndex((x) => x._id == chat._id);
      this.chats.splice(index, 1);
      this.chats.splice(0, 0, chat);
    },

    throwError(message) {
      this.$store.state.snackbar.text = message;
      this.$store.state.snackbar.show = true;
      this.$store.state.snackbar.color = "error";
    },

    createContact(contact) {
      const phones = contact.phones;
      const waID = phones[0].wa_id;
      if (!waID) return this.throwError("Not using WhatsApp");

      const econtact = this.contacts.find((x) => x.waID == waID);
      if (econtact) return this.throwError("Already exists in your contacts.");

      const canonicalForm = "+" + waID;
      const formattedArray =
        libphonenumber.findPhoneNumbersInText(canonicalForm);
      const formattedNumber = formattedArray[0].number;

      this.editedContactItem = {
        deactivated: false,
        name: contact.name.formatted_name,
        callingCode: formattedNumber.countryCallingCode,
        mobile: formattedNumber.nationalNumber,
        waID: waID,
      };

      if (contact.emails.length)
        this.editedContactItem.email = contact.emails[0].email;
      this.$store.state.contactFormModal = true;
    },

    sendChatContactMessage(contact) {
      const phones = contact.phones;
      const waID = phones[0].wa_id;
      if (!waID) return this.throwError("Not using WhatsApp");

      const econtact = this.contacts.find((x) => x.waID == waID);
      if (!econtact)
        return this.throwError("Not in your contacts, please add.");
      this.composeNewChat(econtact);
    },

    searchMessages: _.debounce(function () {
      const keyword = this.rightSearch.keyword;
      if (keyword && keyword.length < 2) return;

      this.rightSearch.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.rightSearch.outstandingRequests++;
      this.rightSearch.loading = true;

      return Inbox.searchMessages(this._id, this.achat._id, keyword)
        .then((x) => {
          this.rightSearch.outstandingRequests--;
          if (thisRequest < this.rightSearch.lastResponse) return;
          this.rightSearch.lastResponse = thisRequest;

          this.rightSearch.messages = x.data || [];
          this.rightSearch.loading = false;
        })
        .catch(() => {
          this.rightSearch.loading = false;
          this.rightSearch.outstandingRequests--;
        });
    }, 500),

    insertEmoji(item) {
      this.message.text += ` ${item}`;
      this.message.text.trim();
      this.$refs.typeMessage.$refs.input.focus();
    },

    formattedName(name) {
      if (!name) return "";
      const lname = name; //.toLowerCase();
      const narray = lname.split(" ");
      const nnarray = narray.map((x) => {
        return (x = x.charAt(0).toUpperCase() + x.slice(1));
      });
      return nnarray.join(" ");
    },

    trim(name) {
      if (!name) return name;
      let nname = this.formattedName(name);
      if (nname.length > 24) nname = nname.substring(0, 20) + "...";
      return nname;
    },
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    account() {
      return this.$store.state.activeAccount;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    messages() {
      return this.achat ? this.achat.messages || [] : [];
    },

    showChats() {
      return !this.newChat.show && !this.profile.show;
    },

    showNewChat() {
      return this.newChat.show && !this.profile.show;
    },

    showProfile() {
      return !this.newChat.show && this.profile.show;
    },

    showRightSearch() {
      return this.rightSearch.show && !this.showInfo;
    },

    isProcessing() {
      return this.loading || this.cloading;
    },

    chatItems() {
      return this.contacts.concat(this.broadcasts);
    },

    placeholder() {
      let string = "Type a message...";
      if (!this.canSendMessage)
        string = "To initiate chat, you must send a template message only.";
      if (this.hasExpired)
        string =
          "This chat has expired, to reinitiate, please send a template message.";
      if (!this.canChat)
        string = "You can not send messages to contacts not using WhatsApp.";
      return string;
    },

    canChat() {
      return !this.achat.isContact || this.achat.isUsingWhatsApp;
    },

    canSendMessage() {
      return (
        this.achat.messages &&
        this.achat.messages.length &&
        this.achat.expiresAt &&
        !this.hasExpired
      );
    },

    hasExpired() {
      return false;
      // return (
      //   this.achat.expiresAt &&
      //   moment(this.achat.expiresAt).diff(moment(), "minutes") < 1
      // );
    },

    sortedChats() {
      this.chats.sort((a, b) => {
        return b.lastMessageAt - a.lastMessageAt;
      });
      this.chats.sort((a, b) => {
        return b.pinned - a.pinned;
      });
      return this.chats;
    },
  },

  watch: {
    "newChat.keyword"(keyword) {
      if (!keyword) {
        this.contacts = this.allContacts;
        this.broadcasts = this.allBroadcasts;
      } else {
        const n = keyword.toLowerCase();
        //contacts
        const filteredContacts = this.allContacts.filter(
          (x) =>
            x.name.toLowerCase().includes(n) ||
            x.mobile.includes(n) ||
            x.email == x
        );
        this.contacts = filteredContacts;

        //broadcasts
        const filteredBroadcasts = this.allBroadcasts.filter(
          (x) =>
            (x.name && x.name.toLowerCase().includes(n)) ||
            "broadcast".includes(n)
        );
        this.broadcasts = filteredBroadcasts;
      }
    },

    "rightSearch.keyword"(n) {
      if (n) this.searchMessages();
    },

    labelsModified(n) {
      if (n) {
        this.achat.labels = n;
        this.labelsModified = false;
      }
    },

    profilePhotoUpdated(n) {
      if (n) {
        if (n.isAccount) this.$store.state.activeAccount.image = n.image;
        else this.achat.image = n.image;
        this.profilePhotoUpdated = false;
      }
    },

    tsearch(n) {
      if (!n) this.templates = this.allTemplates;
      else {
        const filteredTemplates = this.allTemplates.filter((x) =>
          x.name.toLowerCase().includes(n)
        );
        this.templates = filteredTemplates;
      }
    },

    action2(n) {
      if (!n) {
        this.templateItem = {};
        this.composeTemplate = false;
      }
    },

    contactModified(n) {
      if (n) {
        this.getContacts(true);
        this.contactModified = false;
      }
    },

    csearch(keyword) {
      if (keyword) {
        const n = keyword.toLowerCase();
        this.chats = this.allChats.all.filter(
          (x) =>
            x.name.toLowerCase().includes(n) ||
            x.mobile.includes(n) ||
            x.email == x
        );
      } else {
        this.chats = JSON.parse(JSON.stringify(this.allChats.all));
      }
    },

    esearch(n) {
      if (!emojiset) return;
      const emojis = emojiset.default;
      if (n) {
        const keys = Object.keys(emojis);
        const searchedKeys = keys.filter((x) => x.includes(n));

        const newEmojis = searchedKeys.map((x) => emojis[x]);
        this.emojis = newEmojis;
      } else {
        this.emojis = Object.values(emojis);
      }
    },

    "filters.unread"(n) {
      const chats = this.allChats.all;
      if (n) {
        const uchats = chats.filter((x) => x.unreadCount);
        uchats.sort((a, b) => {
          return b.lastMessageAt - a.lastMessageAt;
        });
        uchats.sort((a, b) => {
          return b.pinned - a.pinned;
        });
        this.chats = uchats;
      } else {
        this.chats = chats;
      }
    },
  },

  data() {
    const emailregex =
      /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;
    const validWebsite = (value) => {
      return value.includes("http://") || value.includes("https://");
    };

    return {
      _id: "",
      csearch: "",
      esearch: "",
      loading: true,
      cloading: false,
      bloading: false,
      lloading: false,
      tloading: false,
      riloading: false,

      hasProcessed: false,

      searchIcon: "mdi-magnify",
      wawlogo: require("@/assets/imgs/wlogo.png"),
      walogo: require("@/assets/imgs/logo.png"),

      emojis: Object.values(emojiset.default),

      chats: [],
      allChats: [],

      contacts: [],
      allContacts: [],

      broadcasts: [],
      allBroadcasts: [],

      tsearch: "",
      templates: [],
      allTemplates: [],

      alreadyFetchedFor: [],
      alreadyFetchedBroadcasts: {},
      alreadyFetchedLabels: {},

      achat: false,
      showInfo: false,
      callRedirectionCheck: false,

      action: false,
      action2: false,
      action3: false,
      typeFocus: false,

      media: false,
      avoidPop: false,
      mediaObject: {
        url: "",
        preview: false,
        type: "",
      },

      valid: false,
      message: {
        uploading: false,
        sending: false,
        failed: false,
        text: "",
      },
      dmessage: {
        // to reset
        uploading: false,
        sending: false,
        failed: false,
        text: "",
      },

      templateItem: {
        variables: [],
      },
      composeTemplate: false,

      beingModified: {},
      labelsModified: false,
      profilePhotoUpdated: false,

      // new chat
      newChat: {
        show: false,
        keyword: "",
      },

      rightSearch: {
        show: false,
        keyword: "",
        loading: false,
        messages: [],

        lastSearched: "",
        outstandingRequests: 0,
        lastRequest: 0,
        lastResponse: 0,
      },

      // my-profile
      profile: {
        show: false,

        editAbout: false,
        editBusiness: false,
        processing: false,
        paprocessing: false,
        pbprocessing: false,
        loading: false,
      },

      editedContactItem: {},
      contactModified: false,

      aitems: [
        { _id: "info", title: "Contact info" },
        //{ _id: 'select', title: 'Select messages' },
        { _id: "close", title: "Close chat" },
        //{ _id: 'delete', title: 'Delete chat' },
      ],

      archivedChats: false,
      pitems: [
        { _id: "new-chat", title: "New chat" },
        { _id: "archived", title: "Archived chats" },
        { _id: "mute", title: "Mute notifications" },
        { _id: "logout", title: "Sign out" },
      ],

      citems: [
        { _id: "pin", title: "Pin chat" },
        { _id: "archive", title: "Archive chat" },
        { _id: "mute", title: "Mute notifications" },
        //{ _id: 'delete', title: 'Delete chat' },
      ],

      wabaVerticals: config.wabaVerticals,
      outstandingRequests: 0,
      isWindowActive: false,

      filters: {
        unread: false,
      },

      validationRules: {
        basicRule: [(v) => !!v || "Required"],

        emailRule: [
          (v) => (v && emailregex.test(v)) || "Must be a valid email",
        ],

        websiteRule: [
          (v) => (v && validWebsite(v)) || "Must be a valid website",
        ],
      },
    };
  },
};
