module.exports = {
  apiDev: "http://localhost:8000",
  apiProd: "https://api.waeasyapi.com",

  signinURL: "https://dashboard.waeasyapi.com/signin",
  //signupURL: 'https://dashboard.waeasyapi.com/signup',
  signupURL: "https://localhost:80001/signup",
  appURL: "https://dashboard.waeasyapi.com",

  facebookURL: "https://www.facebook.com/waeasyapi",
  twitterURL: "https://www.twitter.com/waeasyapi",
  telegramURL: "https://t.me/waeasyapi",

  checkmarkAnimationJson:
    "https://assets3.lottiefiles.com/packages/lf20_pmYw5P.json",
  waeaBaseAPI: "https://api.waeasyapi.com/v1/send",

  dashboard360: "https://hub.360dialog.com/dashboard/home",

  new360Form: "https://hub.360dialog.com/dashboard/app/GhJmH7PA/permissions",
  port360Form: "https://hub.360dialog.com/lp/migrate/GhJmH7PA",
  oldNew360Form: "https://hub.360dialog.com/lp/whatsapp/GhJmH7PA",

  facebookNamespace:
    "https://business.facebook.com/wa/manage/message-templates",

  waeasyapiS3: "https://waeasyapi.s3-us-west-1.amazonaws.com/",
  waeasyapiLogo: "https://waeasyapi.s3-us-west-1.amazonaws.com/images/logo.png",

  sampleCampaignCSV:
    "https://waeasyapi.com/files/WA Easy API - Campaign Sample CSV.csv",

  meta: {
    placeholder: "Type here...",
  },

  google: {
    key: "AIzaSyB3LuOl0qVeI4tO9iC4iZA5qTkbXPK-A6w", // google contacts
    key2: "AIzaSyBi7TrXL--opNdIQ1Rv4IWQSJIDvQPcYlo", // javascript map
    clinetID:
      "80715435489-lu1prm6ti2be81a6e0kn93hm6fjap8k6.apps.googleusercontent.com",
  },

  razorpay: {
    key: "rzp_live_yFv9oZYMFZU74j",
    test: "rzp_test_K2Wk8f02V8tAsk",
  },

  colors: [
    "#2C5530",
    "#332E3C",
    "#19381F",
    "#8F3985",
    "#423B0B",
    "#59546C",
    "#011638",
    "#2E294E",
    "#1F1300",
    "#7A542E",
    "#582630",
    "#4F5D2F",
    "#231651",
    "#2374AB",
    "#A44A3F",
    "#F34213",
    "#3E2F5B",
    "#F21B3F",
    "#08BDBD",
    "#874000",
    "#F4442E",
    "#2B0504",
    "#61C9A8",
    "#4C3B4D",
    "#373737",
    "#0F4C5C",
    "#E36414",
    "#5F0F40",
    "#2F243A",
    "#FF312E",
    "#333138",
    "#63585E",
    "#6B6570",
    "#4A314D",
    "#2CA58D",
    "#F46197",
    "#0A2342",
    "#B9BAA3",
    "#902923",
    "#A22C29",
    "#C2BBF0",
    "#3590F3",
    "#0D0C1D",
    "#474973",
    "#161B33",
    "#B4654A",
    "#157A6E",
    "#99621E",
    "#82ABA1",
    "#2E2D4D",
    "#6D9F71",
    "#424874",
    "#629677",
    "#B26E63",
    "#442B48",
    "#1E152A",
    "#60AB9A",
    "#AB3428",
    "#3B8EA5",
    "#38369A",
  ],

  wabaVerticals: [
    "Automotive",
    "Beauty, Spa and Salon",
    "Clothing and Apparel",
    "Education",
    "Entertainment",
    "Event Planning and Service",
    "Finance and Banking",
    "Food and Grocery",
    "Public Service",
    "Hotel and Lodging",
    "Medical and Health",
    "Non-profit",
    "Professional Services",
    "Shopping and Retail",
    "Travel and Transportation",
    "Restaurant",
    "Other",
  ],
};
