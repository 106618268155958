import moment from 'moment';

import Misc from '../../../services/misc';
import Template from '../../../services/template';

import TemplateContent from '../../../components/template-content/template-content.vue';


export default {
  name: 'AccountTemplates',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');
    if (this.account) this.callPhoneNumberButton.callingCode = this.account.callingCode;

    this.fetchTemplates();
    this.fetchCountries();
    this.fetchTemplateEnums();
  },

  components: {
    TemplateContent
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    initPusher() {
      const channelName = this._id;
      const eventName = 'facebookSyncCompleted';
      
      const pusher = this.$store.state.pusher;
      pusher.unsubscribe(channelName);
      const channel = pusher.subscribe(channelName);
      channel.unbind(eventName);
      channel.bind(eventName, (data) => { 
        if (!this.silent) {
          if (this.$route.name == 'account-templates') this.fetchTemplates(); 
          this.notify(data.message); 
        }
        this.silent = false;
      });
    },

    notify(title, body = {}) {
      this.$notification.show(title, body, {});
    },

    fetchTemplates() {
      this.loading = true;
      return Template
        .getTemplates(this._id, false)
        .then(x => {
          this.templates = x.data || [];
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchTemplateEnums() {
      this.eloading = true;
      return Template
        .getEnums(this._id)
        .then(x => {
          this.enums = x.data;
          this.eloading = false;
        })
        .catch(e => {
          console.log(e);
          this.eloading = false;
        });
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) {
        this.countries = cs;
        return;
      }

      this.cloading = true;
      return Misc.getCountries(false)
        .then(res => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
          this.cloading = false;
        })
        .catch(e => { console.log(e); this.cloading = false; });
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    editItem(item) {
      this.editedIndex = this.templates.indexOf(item)
      this.editedItem = Object.assign({}, item)
      setImmediate(() => this.dialog = true, 100);
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    preview(item) {
      this.previewItem = item;
      this.previewDialog = true;
    },

    translateItem(item) {
      const newItem = this.defaultItem;
      newItem.name = item.name;
      newItem.category = item.category;
      this.editItem(newItem);
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    pushButton() {
      const type = this.editedItem.buttons.type;
      if (type === this.cta) {
        const length = this.editedItem.buttons.items.length;
        if (length < 2) {
          const subtype = this.editedItem.buttons.items[0].type;
          if (subtype == this.cpn) {
            this.editedItem.buttons.items.push({ type: this.vwObject._id, urlType: 'STATIC' });
          } else {
            this.editedItem.buttons.items.push({ type: this.cpnObject._id, callingCode: '91' });
          }
        } 
      } else {
        const length = this.editedItem.buttons.items.length;
        if (length < 3) this.editedItem.buttons.items.push({ text: '' });
      }
    },

    availableItemsType(index) {
      const items = this.editedItem.buttons.items;
      const length = items.length;
      if (!length || length == 1) return this.enums.buttonsItemsType;

      if (index == 0 && items[0].type == this.cpn) return [this.cpnObject];
      if (index == 1 && items[1].type == this.vw) return [this.vwObject];

      if (index == 0 && items[0].type == this.vw) return [this.vwObject];
      if (index == 1 && items[1].type == this.cpn) return [this.cpnObject];
    },
    
    removeButton(index) {
      const items = this.editedItem.buttons.items;
      const newArray = items.filter((item, i) => i != index);
      this.editedItem.buttons.items = newArray;
    },

    insertVariable(to) {
      if (to == 'Body') {
        const variables = this.editedItem.body.variables;
        const text = this.editedItem.body.text || '';
        this.editedItem.body.text =  text + '{{'+(variables + 1)+'}}';
        this.editedItem.body.variables = variables + 1;
      } else if (to == 'Header') {
        const variables = this.editedItem.header.variables;

        if (variables) return;
        const text = this.editedItem.header.text || '';
        this.editedItem.header.text =  text + '{{'+(variables + 1)+'}}';
        this.editedItem.header.variables = variables + 1;
      }
    },

    saveTemplate() {
      if (!this.$refs.form.validate()) return;

      Misc.snackbarStart()
      this.processing = true;
      const object = Object.assign({}, this.editedItem);
      return Template
        .createTemplate(this._id, object, false)
        .then(() => {
          this.dialog = false;
          this.processing = false;
          Misc.snackbarSuccess();
          this.fetchTemplates();
          this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        })
        .catch(err => {
          this.processing = false;
          Misc.snackbarError();
          
          let message = 'Oops! Most likely template already exists for this language or you\'re using a recently deleted template name.';
          if (err.response && err.response.data && err.response.data.message) message = err.response.data.message;
          this.$store.state.snackbar.text = message;
        });
    },

    syncTemplates() {
      this.initPusher();
      this.sloading = true;
      Misc.snackbarStart();

      return Template
        .syncTemplates(this._id, false)
        .then(() => {
          this.sloading = false;
          this.fetchTemplates();
          Misc.snackbarSuccess();
          if (!this.silent) {
            this.$store.state.snackbar.text = 'Template Sync Request with Facebook has been submitted. We will inform you once it\'s completed. No action is required from your end. Thanks.';
            this.$store.state.snackbar.timeout = 10000;
          }
        })
        .catch(() => {
          this.sloading = false;
          Misc.snackbarError();
        });
    },

    deleteTemplate() {
      this.processing = true;
      Misc.snackbarStart();
      return Template
        .deleteTemplate(this.beingDeleted._id, false)
        .then(() => {
          this.processing = false;
          this.closeConfirm();
          this.fetchTemplates();
          Misc.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
          this.$store.state.snackbar.text = 'Facebook does not allow to delete sample templates';
        });
    },

    initGrowcifyTemplates() {
      this.gcfyDialog = true;
    },

    submitGrowcifyTemplates() {
      this.silent = true;
      this.gprocessing = true;
      Misc.snackbarStart();
      return Template
        .gcfyTemplates(this._id, this.gcfyTemplates, false)
        .then(() => {
          this.gcfyDialog = false;
          this.gprocessing = false;
          this.syncTemplates();
          Misc.snackbarSuccess();
        })
        .catch(() => {
          this.gprocessing = false;
          Misc.snackbarError();
        });
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    account() {
      return this.$store.state.activeAccount;
    },

    indexed() {
      return this.templates.map((x, index) => {
        x.index = (index + 1)+'.';
        return x;
      })
    },

    buildingBlocks() {
      if (this.enums && this.enums.blockTypes) {
        return this.enums.blockTypes.map(x => {
          return {
            _id: x._id,
            name: x.name,
            disabled: x._id == 'BODY'
          }
        })
      } 
      return [];
    },

    cpnObject() {
      return { _id: this.cpn, name: 'Call Phone Number', callingCode: this.account.callingCode };
    },

    vwObject() {
      return { _id: this.vw, name: 'Visit Website', urlType: 'STATIC' };
    },

    cpn() {
      return 'PHONE_NUMBER';
    },

    vw() {
      return 'URL';
    },

    cta() {
      return 'CALL_TO_ACTION';
    },

    bstype() {
      return this.editedItem.buttons.type;
    },

    buttonComponent() {
      return this.previewItem ? this.previewItem.components.find(x => x.type == 'BUTTONS') : false;
    },

    gcfyt1() {
      const array = JSON.parse(JSON.stringify(this.gcfyTemplates));
      array.length = 8;
      return array;
    },

    gcfyt2() {
      const narray = this.gcfyTemplates.filter(x => !this.gcfyt1.includes(x));
      return narray;
    }
  },

  watch: {
    'editedItem.buttons.type'(n) {
      if (n) {
        if (n == this.cta) this.editedItem.buttons.items = [this.callPhoneNumberButton];
        else this.editedItem.buttons.items = [{ text: '' }];
      }
    },

    'editedItem.buttons.type.items.type'(n) {
      if (n) {
        if (n == this.cpn) this.editedItem.buttons.items = [this.callPhoneNumberButton];
        else this.editedItem.buttons.items = [this.visitWebsiteButton];
      }
    },

    'editedItem.name'(value) {
      if (value) {
        value = value.toLowerCase();
        value = value.replace(/[^\w\s]/gi, '');
        value = value.replace(' ', '_');
        this.editedItem.name = value.trim();
      }
    }
  },

  data() {
    const validTemplate = (value) => {
      return !!value;
    };

    return {
      _id: '',
      loading: false,
      cloading: false,
      sloading: false,
      eloading: false,
      processing: false,
      gprocessing: false,
      firstLoad: false,
      silent: false,

      enums: {},
      search: '',
      templates: [],
      countries: [],

      dialog: false,
      templatesObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10
      },
      filters: {
        labels: [],
      },

      options: {},
      confirmDialog: false,
      beingDeleted: {},

      previewDialog: false,
      previewItem: false,

      editedItem: {
        type: 'STANDARD',
        language: 'en',
        blocks: ['BODY'],
        header: {
          variables: 0
        },
        body: {
          text: '',
          variables: 0
        },
        footer: {},
        buttons: {
          items: []
        }
      },
      defaultItem: {
        type: 'STANDARD',
        language: 'en',

        blocks: ['BODY'],
        header: {
          variables: 0
        },
        body: {
          text: '',
          variables: 0
        },
        footer: {},
        buttons: {
          items: []
        }
      },
      editedIndex: -1,

      callPhoneNumberButton: {
        type: 'PHONE_NUMBER',
        callingCode: '91',
        number: '',
        text: ''
      },

      visitWebsiteButton: {
        type: 'URL',
        urlType: 'STATIC',
        url: '',
        text: '',
        variables: 0
      },

      gcfyDialog: false,
      gcfyTemplates: [
        'grcfy_otp',
        'grcfy_order_new',
        'grcfy_order_received',
        'grcfy_order_accepted',
        'grcfy_order_shipped',
        'grcfy_order_delivered',
        'grcfy_order_cancelled',
        'grcfy_order_assigned',
        'grcfy_subscription_new',
        'grcfy_subscription_received',
        'grcfy_subscription_paused',
        'grcfy_subscription_completed',
        'grcfy_subscription_cancelled',
        'grcfy_subscription_order_failed',
        'grcfy_refund_processed',
        'grcfy_paymentlink'
      ],

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Category', value: 'category' },
        { text: 'Languages', value: 'language' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'right'},
      ],

      validationRules: {
        basicRule: [
          v => !!v || 'Required'
        ],
        nameRule: [
          v => validTemplate(v) || 'Only lowercase alphanumeric characters and underscores ( _ )'
        ]
      }
    }
  }
}