import moment from 'moment';

export default {
  name: 'AccountAgents',

  created() {
    this._id = this.$route.params._id;
    if (!this._id) return this.navigate('home');
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    account() {
      return this.$store.state.activeAccount;
    }
  },

  data() {
    return {
      _id: '',
      loading: true
    }
  }
}