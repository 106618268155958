import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

import config from '../../config';

import Misc from '../../services/misc';
import User from '../../services/user';
import Account from '../../services/account';

import AccountBanner from '../../components/account-banner/account-banner.vue';

export default {
  name: 'Dashboard',

  created() {
    if (!this.amISuperadmin) return this.navigate('home');
    this.fetchNumbers();
    this.fetchAccounts();
    this.fetchUsers();
    this.fetchRoles();
    this.fetchCountries();
  },

  components: {
    AccountBanner
  },

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) {
        this.countries = cs;
        return;
      }

      this.cfetching = true;
      return Misc.getCountries(false)
        .then(res => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
          this.cfetching = false;
        })
        .catch(e => { console.log(e); this.cfetching = false; });
    },

    fetchAccounts() {
      this.loading = true;
      return Account.getAllAccounts(this.user._id, true)
        .then(x => {
          const data = x.data || [];
          this.accounts = data;

          this.accounts.forEach((x, index) => {
            x.index = (index + 1)+'.';
            x.userName = x.user.name || ('+'+x.user.callingCode+' '+x.user.mobile) || '-';

            if (x.inDraft) x.statusd = 'In Draft';
            else if (x.isActive && x.hasPaid && !x.key) x.statusd = 'API Key Missing';
            else if (x.isActive && x.hasPaid && !x.inPast) x.statusd = 'Active';
            else if (x.inPast) x.statusd = 'Renewal Due';
            else x.statusd = 'Inactive';

            if (x.dueDate) x.dueDate =  moment(x.dueDate).format('YYYY-MM-DD');
          });


          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        })
    },

    fetchUsers() {
      this.uloading = true;
      return User.getAllUsers(true)
        .then(x => {
          const data = x.data || [];
          this.users = data;
          this.users.forEach((x, index) => {
            x.index = (index + 1)+'.';
          });
          this.uloading = false;
        })
        .catch(e => {
          console.log(e);
          this.uloading = false;
        })
    },

    fetchNumbers() {
      this.nloading = true;
      return Account.getNumbers(true)
        .then(x => {
          const data = x.data || [];
          this.numbers = data;
          this.nloading = false;
        })
        .catch(e => {
          console.log(e);
          this.nloading = false;
        })
    },

    fetchRoles() {
      return User.getRoles()
        .then(x => {
          this.roles = x.data.map(x => { 
            return { 
              key: x, 
              value: x, 
              disabled: x === 'admin' || x === 'customer'
            } 
          });
        })
        .catch(e => console.log(e));
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    updateAPIKey(item) {
      this.editedAccountItem = item;
      this.apiKeyModal = true;
    },

    copy(content) {
      navigator.clipboard.writeText(content);
      this.copyText = 'Copied';
      setTimeout(() => this.copyText = 'Copy', 1000);
    },

    saveAccount() {
      if (!this.$refs.form.validate()) return;
      Misc.snackbarStart();
      this.processing = true;

      const account = this.editedAccountItem;
      return Account
        .editAccount(account._id, account, true)
        .then(() => {
          this.processing = false;
          this.closeAccount();
          Misc.snackbarSuccess();
          this.fetchAccounts();
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
        });
    },

    closeAccount() {
      this.accountDialog = false;
    },

    callingCodeText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    editAccountItem(item) {
      this.editedAccountItem = item;
      this.accountDialog = true;
    },

    closeConfirm() {
      this.beingDeleted = {};
      this.confirmDialog = false;
    },

    deleteAccountItem(item) {
      item.isAccount = true;
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    finalDelete() {
      if (this.beingDeleted.isAccount) {
        Misc.snackbarStart();
        this.processing = true;
        return Account
          .deleteAccount(this.beingDeleted._id)
          .then(() => {
            this.closeConfirm();
            this.processing = false;
            Misc.snackbarSuccess();
            this.fetchAccounts();
          })
          .catch(() => {
            this.processing = false;
            Misc.snackbarError();
          });
      }
    },

    getSymbolFromCurrency(code) {
      return getSymbolFromCurrency(code);
    },

    closeUser() {
      this.userDialog = false;
    },

    editUserItem(item) {
      this.editedUserItem = item;
      this.userDialog = true;
    },

    saveUser() {
      if (!this.$refs.form1.validate()) return;
      Misc.snackbarStart();
      this.processing = true;

      const user = this.editedUserItem;
      return User
        .editUser(user._id, user, true)
        .then(() => {
          this.processing = false;
          this.closeUser();
          Misc.snackbarSuccess();
          this.fetchUsers();
        })
        .catch(() => {
          this.processing = false;
          Misc.snackbarError();
          this.$store.state.snackbar.text = 'Number Already Exists';
        });
    }
  },

  computed: {
    user() {
      return this.$store.state.userInfo;
    },

    activeAccounts() {
      return this.accounts.length ? this.accounts.filter(x => x.isActive && x.hasPaid).length : 0;
    },

    inactiveAccounts() {
      return this.accounts.length ? this.accounts.filter(x => x.inDraft || !x.isActive).length : 0;
    },

    darkDullColor() {
      return this.isDT ? 'grey darken-3' : 'grey lighten-3'
    },

    amISuperadmin() {
      return Misc.amISuperadmin();
    },

    accountTitle() {
      return this.editedAccountItem._id ? 'Edit account' : 'Create new account';
    },

    userTitle() {
      return this.editedUserItem._id ? 'Edit user' : 'Add new user';
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  watch: {
    keyword(n) {
      if (n) this.accounts = this.allAccounts.filter(item => item && (
        item.name.toString().toLowerCase().includes(n) || 
        item.company.toString().toLowerCase().includes(n) || 
        item.mobile.includes(n)
      ))
      else this.accounts = this.allAccounts;
    }
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      loading: true,
      accounts: [],
      keyword: '',

      processing: false,
      nloading: false,

      apiKeyModal: false,
      renewSecretModal: false,

      accountDialog: false,
      editedAccountItem: {},
      defaultAccountItem: {},

      userDialog: false,
      editedUserItem: {},
      defaultUserItem: {},

      copyText: 'Copy',
      waeaBaseAPI: config.waeaBaseAPI,

      beingDeleted: {},
      confirmDialog: false,

      search: '',
      numbers: {
        inrRevenue: 0,
        usdRevenue: 0,
        payments: 0,
      },

      cfetching: false,
      countries: [],
      selectedCountry: {},
      calModal: false,
      roles: [],

      headers: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Company', value: 'company', sortable: false },
        { text: 'Name', value: 'userName', sortable: false },
        { text: 'WhatsApp Number', value: 'mobile', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'statusd' },
        //{ text: 'Due Date', value: 'dueDate' },
        { text: 'Created', value: 'createdAt' },
        { text: 'Actions', align: 'right', value: 'actions', sortable: false},
      ],

      users: [],
      usearch: '',
      uloading: false,
      uprocessing: false,
      uheaders: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Mobile', value: 'mobile', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Status', value: 'deactivated' },
        { text: 'Created', value: 'createdAt' },
        { text: 'Actions', align: 'right', value: 'actions', sortable: false},
      ],

      logo: require('@/assets/imgs/logo.png'),
      sapi: require('@/assets/imgs/screenshot-api.png'),

      validationRules: {
        basicRule: [
          v => !!v || 'Required'
        ],
        statusRule: [
          v => v || v === false || 'It is required'
        ],

        numberRule: [
          v => (v && !isNaN(v)) || 'Enter a valid number'
        ],
        emailRule: [
          v => !v || emailregex.test(v) || 'Must be a valid email'
        ],
      },
    }
  }
}