<template>
  <div :class="!hideMargin ? 'mb-2' : ''">
    <v-progress-circular color="primary" indeterminate :size="size || 18" :width="width || 2"></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: 'MiniLoader',

  props: [
    'size',
    'width',
    'hideMargin'
  ]
}
</script>