import Vue from 'vue'
import App from './app.vue'
import router from './router'
import moment from 'moment'
import VueAxios from 'vue-axios'
import AOS from 'aos';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import titleMixin from './mixins/titleMixin';
import http from './http'
import store from './store'
import config from './config'
import vuetify from './plugins/vuetify';
import NProgress from 'nprogress';
import Vue2Filters from 'vue2-filters';
import DatetimePicker from 'vuetify-datetime-picker';
import VueNativeNotification from 'vue-native-notification';
import * as VueGoogleMaps from 'vue2-google-maps'

//css import
import './assets/css/custom.scss'
import 'aos/dist/aos.css';


Vue.config.productionTip = false;
Vue.mixin(titleMixin);
Vue.use(VueAxios, http);
Vue.use(Vue2Filters);
Vue.use(VueViewer);
Vue.use(DatetimePicker);
Vue.use(VueNativeNotification, {
  requestOnNotify: true
});
Vue.use(VueGoogleMaps, {
  load: {
    key: config.google.key2
  }
});

Vue.filter('date', date => moment(date).format('MMMM DD, YYYY'));

Vue.filter('fc', function (value) {
  const currency = store.state.country.currencyCode || 'USD';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(value)
});

Vue.filter('fcn', function (value, currency = 'USD') {
  if (!value) value = 0;
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(value)
});

Vue.filter('firstLetterUppercase', function (value) {
  if (!value) return ''
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('firstName', function(value) {
  if (!value || value == '-') return value;
  return value.split(' ').length === 1 ? value : value.split(' ').slice(0, -1).join(' ');
});

Vue.filter('initials', function(value) {
  if (!value || value == '-') return value;
  const name = value.split(' ');
  return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
});

Vue.filter('fn', function(value) {
  return value ? value.toLocaleString() : 0;
})

vuetify.framework.theme.themes.light.primary = '#075E54';

NProgress.configure({ showSpinner: false });
NProgress.start();
new Vue({
  created () {
    AOS.init();
  },
  router,
  render: h => h(App),
  store,
  vuetify
}).$mount('#app')
