import Vue from 'vue';

export default {
  getAllUsers(noProgress = true) {
    return Vue.axios.get(`user/list/all`, { noProgress: noProgress });
  },

  editUser(userId, user, noProgress = true) {
    return Vue.axios.put(`user/${userId}`, user, { noProgress: noProgress });
  },

  editProfile(userId, profile, noProgress = true) {
    return Vue.axios.put(`user/profile/${userId}`, profile, { noProgress: noProgress });
  },

  getRoles(noProgress = true) {
    return Vue.axios.get(`user/roles`, { noProgress: noProgress });
  }
}