import Vue from 'vue';

export default {
  getContacts(accountId, page, pageSize, filters, keyword, noProgress = true) {
    let endpoint = `contact/list/${accountId}/${page}/${pageSize}`;
    if (keyword) endpoint += '?keyword='+keyword;

    return Vue.axios.post(endpoint, filters, { noProgress: noProgress });
  },

  getAllContacts(accountId, noProgress = true) {
    return Vue.axios.get(`contact/list/all/${accountId}`, { noProgress: noProgress });
  },

  addContact(accountId, contact, noProgress = true) {
    return Vue.axios.post(`contact/${accountId}`, contact, { noProgress: noProgress });
  },

  editContact(contactId, contact, noProgress = true) {
    return Vue.axios.put(`contact/${contactId}`, contact, { noProgress: noProgress });
  },

  deleteContact(contactId, noProgress = true) {
    return Vue.axios.delete(`contact/${contactId}`, { noProgress: noProgress });
  },

  syncGoogleContacts(accountId, object, noProgress = true) {
    return Vue.axios.post(`contact/sync/google/${accountId}`, object, { noProgress: noProgress });
  },

  syncGrowcifyCustomers(accountId, noProgress = true) {
    return Vue.axios.post(`contact/sync/growcify/${accountId}`, {}, { noProgress: noProgress });
  },

  getContactBroadcasts(accountId, contactId, noProgress = true) {
    return Vue.axios.get(`contact/list/broadcast/${accountId}/${contactId}`, { noProgress: noProgress });
  },

  getContactLabels(accountId, contactId, noProgress = true) {
    return Vue.axios.get(`contact/list/label/${accountId}/${contactId}`, { noProgress: noProgress });
  },

  //labels
  getLabels(accountId, noProgress = true) {
    return Vue.axios.get(`label/list/${accountId}`, { noProgress: noProgress });
  },

  createLabel(accountId, label, noProgress = true) {
    return Vue.axios.post(`label/${accountId}`, label, { noProgress: noProgress });
  },

  editLabel(labelId, label, noProgress = true) {
    return Vue.axios.put(`label/${labelId}`, label, { noProgress: noProgress });
  },

  deleteLabel(labelId, noProgress = true) {
    return Vue.axios.delete(`label/${labelId}`, { noProgress: noProgress });
  },

  //broad casts
  getBroadcasts(accountId, page, pageSize, filters, keyword, noProgress = true) {
    let endpoint = `broadcast/list/${accountId}/${page}/${pageSize}`;
    if (keyword) endpoint += '?keyword='+keyword;

    return Vue.axios.post(endpoint, filters, { noProgress: noProgress });
  },

  getAllBroadcasts(accountId, noProgress = true) {
    return Vue.axios.get(`broadcast/list/all/${accountId}`, { noProgress: noProgress });
  },

  createBroadcast(accountId, broadcast, noProgress = true) {
    return Vue.axios.post(`broadcast/${accountId}`, broadcast, { noProgress: noProgress });
  },

  editBroadcast(broadcastId, broadcast, noProgress = true) {
    return Vue.axios.put(`broadcast/${broadcastId}`, broadcast, { noProgress: noProgress });
  },

  deleteBroadcast(broadcastId, noProgress = true) {
    return Vue.axios.delete(`broadcast/${broadcastId}`, { noProgress: noProgress });
  },

  getBroadcastLabels(accountId, broadcastId, noProgress = true) {
    return Vue.axios.get(`broadcast/list/label/${accountId}/${broadcastId}`, { noProgress: noProgress });
  },
}