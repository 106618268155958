import moment from 'moment';

export default {
  name: 'AccountBanner',

  props: [
    'account'
  ],

  methods: {
    navigate(name, params = {}) {
      this.$router.push({ name: name, params: params }).catch(() => {});
    },

    gotoAccount(item) {
      if (item.inDraft) this.navigate('draft-account', { _id: item._id });
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    pay() {
      this.$parent.processRenewal(this.account);
    }
  },

  computed: {
    isRenewalDue() {
      return this.account.isTenDaysToGo || this.account.accessDisabled;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return { }
  }
}