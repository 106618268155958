import * as linkify from 'linkifyjs';
import { gmapApi } from 'vue2-google-maps'

import Inbox from '../../services/inbox';

import MediaLoader from '../media-loader/media-loader.vue';
import ImageWithLoader from '../image-with-loader/image-with-loader.vue';
import MessageTimer from '../message-timer/message-timer.vue';

export default {
  name: 'MessageItem',

  props: [
    '_id',
    'msg',
    'achat',
    'right',
    'sendMessage',
    'createContact'
  ],

  components: {
    MediaLoader,
    MessageTimer,
    ImageWithLoader
  },

  created() {
    if (this.msg.type == 'location') {
      setTimeout(() => {
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat: this.msg.location.latitude, lng: this.msg.location.longitude })
        })
      }, 0)
    }
  },

  methods: {
    urlify(text) {
      const response = linkify.find(text);
      if (!response || !response.length) return text;

      const processed = [];
      response.forEach(x => {
        if (['url', 'email'].includes(x.type)) {
          if (!processed.includes(x.href)) text = text.replaceAll(x.value, `<a href="${x.href}" target="_blank" class="text-link">${x.value}</a>`);
          processed.push(x.href);
        }
      });

      return text;
    },

    getFormatted(text) {
      // bold check
      const bold = /\*(.*?)\*/gm;
      text = text.replace(bold, '<b>$1</b>');            

      // not useful for template names 
      // const italic = /_(.*?)_/gm;
      // text = text.replace(italic, '<i>$1</i>'); 

      // strike check
      const strike = /~(.*?)~/gm;
      text = text.replace(strike, '<s>$1</s>'); 

      // link check
      if (text) text = this.urlify(text);
      return text;
    },

    show() {
      const imgMessages = this.achat.messages.filter(x => ['image', 'sticker'].includes(x.type));
      const index = imgMessages.findIndex(x => x._id == this.msg._id);
      const images = imgMessages.map(x => {
        if (x.type == 'image') return x.image.link || 'data:image/jpeg;base64,'+x.file;
        if (x.type == 'sticker') return x.sticker.link || 'data:image/jpeg;base64,'+x.file;
      });

      this.$viewerApi({
        options: { 
          button: false,
          loop: false,
          title: false,
          navbar: false,
          toolbar: false,
          loading: true,
          initialViewIndex: index 
        },
        images: images,
      })
    },

    async getMedia(mediaId) {
      const mresponse = await Inbox.getMedia(this._id, mediaId);
      const rdata = mresponse.data;
      if (rdata.ok) this.msg.file = rdata.data;
      return;
    },

    async downloadDocument() {
      this.downloading = true;
      if (!this.msg.file && !this.mediaLink) {
        if (this.msg.document.id) await this.getMedia(this.msg.document.id);
        else return;
      }

      const a = document.createElement('a'); 
      a.href = this.mediaLink || `data:${this.msg.document.mime_type};base64,` + this.msg.file;
      a.download = this.msg.document.filename || 'Untitled';
      a.click();
      setTimeout(() => this.downloading = false, 50);
    },

    openMap() {
      if (this.msg.type != 'location') return;

      let url = '';
      if (this.msg.location.name) url = `https://www.google.com/maps/search/${this.msg.location.name}/@${this.msg.location.latitude},${this.msg.location.longitude},17z?hl=en`
      else url = `https://www.google.com/maps?q=${this.msg.location.latitude},${this.msg.location.longitude}`;
      window.open(url, '_blank');
    },

    emojis(length) {
      if (this.onlyEmoji && this.msg.text.length == length) return true;
      return false;
    },

    contactMessage(item) {
      this.sendMessage(item);
    },

    contactCreate(item) {
      this.createContact(item);
    },

    processButton(item) {
      if (item.type == 'URL') window.open(item.url, '_blank');
      else if (item.type == 'PHONE_NUMBER') {
        const number = item.phone_number;
        window.location.href = 'tel:' + number
      }
    }
  },

  computed: {
    mclass() {
      let cl = 'mmedia';
      if (!this.msg.loading && (this.msg.file || this.mediaLink)) {
        if (this.msg.type == 'image') cl = 'imedia';
        else if (['audio', 'voice'].includes(this.msg.type)) cl = 'audiomedia';
      }
      if (this.isDocument) cl = 'dmedia'
      return cl;
    },

    mclass2() {
      let cl = '';
      if (!this.msg.loading && (this.msg.file || this.mediaLink)) {
        if (this.msg.type == 'image' && !this.msg.image.caption) cl = 'imedia2';
      }
      return cl;
    },

    isSticker() {
      return this.msg && this.msg.type == 'sticker';
    },

    isImage() {
      return this.msg && this.msg.type == 'image';
    },

    dicon() {
      let icon = 'mdi-file-document';
      if (!this.msg || !this.msg.document || this.msg.document.link) return icon;
      
      const mime = this.msg.document.mime_type;
      if (mime.includes('pdf')) icon = 'mdi-file-pdf';
      else if (mime.includes('png') || mime.includes('jpeg')) icon = 'mdi-image';
      else if (mime.includes('word')) icon = 'mdi-file-word';
      else if (mime.includes('excel') || mime.includes('csv')) icon = 'mdi-file-excel';
      else if (mime.includes('mp3') || mime.includes('ogg')) icon = 'mdi-file-music'; 
      else if (mime.includes('pptx') || mime.includes('presentation')) {
        this.msg.document.mime_type = 'presentation/pptx'; // for sort name
        icon = 'mdi-file-powerpoint';
      }

      return icon;
    },

    isMedia() {
      return this.msg && 
        [
          'image',
          'audio',
          'video',
          'voice',
          'document',
          'sticker'
        ].includes(this.msg.type);
    },

    mediaLink() {
      if (!this.msg) return;

      const type = this.msg.type;
      const isMedia = this.isMedia;
      return isMedia ? this.msg[type].link || '' : ''
    },

    google: gmapApi,

    latitude() {
      return parseFloat(this.msg.location.latitude)
    },
    
    longitude() {
      return parseFloat(this.msg.location.longitude)
    },

    mapCenter() {
      if (this.msg.type != 'location') return;
      return { lat: this.latitude, lng: this.longitude }
    },

    mapPosition() {
      if (this.msg.type != 'location') return;
      return this.google && new this.google.maps.LatLng(this.latitude, this.longitude)
    },

    containsLetter(str) {
      return /[a-zA-Z]/.test(str);
    },

    onlyEmoji() {
      if (this.msg.type != 'text' || !this.msg.text) return false;

      const str = this.msg.text;
      const nregex = /\d/;
      const regex = /\p{Emoji}/u;
      const sregex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      const cregex = /[a-zA-Z]/;

      if (regex.test(str) && 
        !nregex.test(str) && 
        !sregex.test(str) && 
        !cregex.test(str)) return true;

      return false;
    },

    emojiClass() {
      let cname = ''
      if (this.emojis(2)) cname = 'one-emoji';
      else if (this.emojis(4)) cname = 'two-emoji';
      else if (this.emojis(6)) cname = 'three-emoji';
      else if (this.onlyEmoji) cname = 'emoji-only';
      return cname;
    },

    headerComponent() {
      if (!this.msg || !this.msg.params) return;
      const components = this.msg.params.components;
      if (!components) return {};
      return components.find(x => ['header', 'HEADER'].includes(x.type));
    },

    footerComponent() {
      if (!this.msg || !this.msg.params) return;
      const components = this.msg.params.components;
      if (!components) return {};
      return components.find(x => ['footer', 'FOOTER'].includes(x.type));
    },

    buttonsComponent() {
      if (!this.msg || !this.msg.params) return;
      const components = this.msg.params.components;
      if (!components) return { buttons: [] };
      return components.find(x => ['buttons', 'BUTTONS'].includes(x.type));
    },

    isDocument() {
      return ['document'].includes(this.msg.type);
    }
  },

  data() {
    return {
      downloading: false,

      mapOptions: {
        mapId: '2bb4c7ad3386f42e',
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true
      }
    }
  }
}